import {
  CommandCenterMembersStatusProvider,
  useCommandCenterMembersStatus,
} from "../containers/members/status";
import { MembersHeader } from "./index";

export const Component = () => {
  const {
    inputValue,
    setInputValue,
    parentRef,
    member,
    handleSetMemberFree,
    handleCancel,
    isSaving,
  } = useCommandCenterMembersStatus();

  return (
    <>
      <div id="command-center-header" className="shadow">
        <MembersHeader />
        <div className="relative border-b border-neutral-600">
          <h2 className="px-4 py-4 text-xl">
            Make {member?.givenName || "..."} a Free member...
          </h2>
        </div>
      </div>
      <div
        // @ts-ignore
        ref={parentRef}
        style={{ overflow: "hidden auto" }}
        className="relative w-full px-4 py-8"
      >
        <div className="flex justify-end w-full">
          <button
            type="button"
            onClick={handleCancel}
            className="w-full px-4 py-2 text-sm font-medium bg-background border rounded-full shadow-sm text-muted-foreground border-neutral-700 hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2"
          >
            Cancel
          </button>
          <button
            onClick={handleSetMemberFree}
            className="inline-flex justify-center w-full px-4 py-2 ml-3 text-sm font-medium text-black border border-transparent rounded-full shadow-sm bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2"
          >
            Confirm
          </button>
        </div>
      </div>
    </>
  );
};

export const CommandCenterMembersStatusContext = () => {
  return (
    <CommandCenterMembersStatusProvider>
      <Component />
    </CommandCenterMembersStatusProvider>
  );
};
