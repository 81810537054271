// use-hls-media.ts
import * as Sentry from "@sentry/nextjs";
import type { ErrorData } from "hls.js";
import Hls from "hls.js";
import last from "lodash/last";
import React, { useEffect, useRef, useState } from "react";

interface UseHlsMediaProps {
  autoPlay?: boolean;
  url: string;
  ref: React.RefObject<HTMLVideoElement>;
}

interface UseHlsMediaReturn {
  error: boolean;
  isSupported: boolean;
  isHlsMedia: boolean;
  isNativeSupported: boolean;
  cleanUpHls: () => void;
}

export const checkIsHlsMedia = (url: string): boolean => {
  try {
    if (!url || typeof url !== "string") return false;
    const extension = last(url.split("."));
    return extension === "m3u8";
  } catch (error) {
    console.error({ url, error });
    Sentry.captureException(error, {
      tags: { function: "checkIsHlsMedia" },
      extra: { url },
    });
    return false;
  }
};

export const useHlsMedia = ({
  autoPlay = false,
  url,
  ref: playerRef,
}: UseHlsMediaProps): UseHlsMediaReturn => {
  const [retryAttempts, setRetryAttempts] = useState<number>(0);
  const [isSupported, setIsSupported] = useState<boolean>(true);
  const [isHlsMedia, setIsHlsMedia] = useState<boolean>(checkIsHlsMedia(url));
  const [isNativeSupported, setIsNativeSupported] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const hlsRef = useRef<Hls | null>(null);

  const cleanUpHls = () => {
    if (hlsRef.current) {
      hlsRef.current.destroy();
      hlsRef.current = null;
    }
  };

  const handleHlsError = (event: string, data: ErrorData) => {
    Sentry.captureMessage(`HLS Error: ${data.type}`, {
      level: "error",
      tags: { hlsEvent: data.type },
      extra: { event, data, url },
    });

    switch (data.type) {
      case Hls.ErrorTypes.NETWORK_ERROR:
        if (retryAttempts < 25) {
          setRetryAttempts((prev) => prev + 1);
          setTimeout(() => {
            hlsRef.current?.recoverMediaError();
          }, retryAttempts * 500);
        } else {
          Sentry.captureMessage(
            "Max retry attempts reached for NETWORK_ERROR",
            {
              level: "warning",
              extra: { url },
            }
          );
          setError(true);
        }
        break;
      case Hls.ErrorTypes.MEDIA_ERROR:
        try {
          hlsRef.current?.recoverMediaError();
        } catch (recoverError) {
          Sentry.captureException(recoverError, {
            tags: { hlsEvent: "MEDIA_ERROR_RECOVERY_FAILED" },
            extra: { url },
          });
          setError(true);
          cleanUpHls();
        }
        break;
      default:
        setError(true);
        Sentry.captureMessage(`Unrecoverable HLS error: ${data.type}`, {
          level: "error",
          extra: { url },
        });
        cleanUpHls();
        break;
    }
  };

  useEffect(() => {
    setIsHlsMedia(checkIsHlsMedia(url));

    // Check for native HLS support
    const video = document.createElement("video");
    const canPlay = video.canPlayType("application/vnd.apple.mpegurl");
    setIsNativeSupported(!!canPlay);
  }, [url]);

  useEffect(() => {
    if (!isHlsMedia) {
      return;
    }

    if (isNativeSupported) {
      // Native HLS is supported; no need for hls.js
      return;
    }

    if (!url) {
      Sentry.captureMessage("URL is not provided", {
        level: "warning",
      });
      return;
    }

    if (!playerRef.current) {
      Sentry.captureMessage("Player reference is not available", {
        level: "warning",
        extra: { url },
      });
      return;
    }

    if (!Hls.isSupported()) {
      setIsSupported(false);
      return;
    }

    const hls = new Hls();
    hlsRef.current = hls;

    hls.attachMedia(playerRef.current);

    hls.on(Hls.Events.MEDIA_ATTACHED, () => {
      hls.loadSource(url);
    });

    hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
      setError(false);
      if (autoPlay) {
        playerRef.current?.play().catch((playError) => {
          Sentry.captureException(playError, {
            tags: { function: "autoPlay" },
            extra: { url },
          });
        });
      }
    });

    hls.on(Hls.Events.ERROR, handleHlsError);

    // Cleanup on unmount or when dependencies change
    return () => {
      cleanUpHls();
    };
  }, [url, isHlsMedia, autoPlay, isNativeSupported]);

  return {
    error,
    isSupported,
    isHlsMedia,
    isNativeSupported,
    cleanUpHls,
  };
};
