export const Folder = (props) => {
  return (
    <svg
      width="65"
      height="47"
      viewBox="0 0 65 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 5C10 2.23858 12.2386 0 15 0H21.1133C22.308 0 23.4401 0.533986 24.2 1.45586L25.5 3.03311C26.4498 4.18546 27.865 4.85294 29.3583 4.85294H51C53.7614 4.85294 56 7.09152 56 9.85294V45H10V5Z"
        fill="#004CBF"
      />
      <path
        d="M1.61652 19.2976C0.786729 15.5516 3.6377 12 7.47452 12H57.7974C61.5453 12 64.3758 15.3979 63.6987 19.0841L59.4736 42.0841C58.9504 44.932 56.4679 47 53.5723 47H12.5693C9.75562 47 7.31986 45.0447 6.71133 42.2976L1.61652 19.2976Z"
        fill="#3383FC"
      />
    </svg>
  );
};
