import { XCircleIcon } from "@heroicons/react/solid";
import { motion } from "framer-motion";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import Cross from "ui/components/icons/Cross";
import { Folder } from "ui/components/illustrations/folder";
import { useFileUploader } from "ui/containers/file-uploader";
import { getUploadingMessage } from "ui/lib/helpers";
import { cn } from "ui/lib/utils";
import { VideoRecorder } from "../recorder";

export const VideoUploader = ({
  accept = ["mov", "mp4"],
  onClose,
  onDrop,
  uploadId,
  disabled,
  error,
}) => {
  const [recordOpen, setRecordOpen] = useState(false);
  const { currentUploads } = useFileUploader();
  const upload = currentUploads[uploadId];

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled,
  });

  if (recordOpen) {
    return (
      <VideoRecorder
        // @ts-ignore
        open={recordOpen}
        onOpenChange={setRecordOpen}
        handleSave={(file) => {
          onDrop([file]);
          setRecordOpen(false);
        }}
      />
    );
  }

  return (
    <motion.div
      key="video-upload"
      className="relative flex flex-col w-full h-full"
    >
      <div
        className={cn("px-8 pt-16 pb-8 flex-col flex-grow", {
          "text-left": !!upload,
          "flex-center text-center": !upload,
        })}
      >
        <div className="mb-8">
          <h4 className={cn("text-2xl font-bold text-foreground")}>
            Upload your video {disabled ? " (disabled)" : ""}
          </h4>
          <div className="text-muted-foreground text-md">
            {!upload ? (
              `videos should be ${accept.join(", ")}`
            ) : (
              <div className="flex flex-col">
                <span>
                  Feel free to close this pop-up and continue using Apollo while
                  the video is uploading.
                </span>
                <span className="flex items-center mt-4 space-x-2 font-medium">
                  <div className="p-2 rounded-full">
                    <img
                      src="/assets/images/warning.png"
                      className="w-5 h-5"
                      alt={"warning"}
                    />
                  </div>
                  <span>While uploading, do not close out of Apollo</span>
                </span>
              </div>
            )}
          </div>
        </div>
        {!upload ? (
          <div
            {...getRootProps()}
            className={cn(
              "border-2 border-dashed bg-royal/10 rounded w-full cursor-pointer",
              {
                "border-royal": !isDragActive,
                "border-white": isDragActive,
              }
            )}
          >
            <input className="hidden" {...getInputProps()} />
            <div className="flex-col p-12 space-y-6 flex-center">
              <Folder />
              <h5 className="text-lg text-royal">
                Drag & Drop your video here
              </h5>
            </div>
          </div>
        ) : (
          <div>
            <div
              className={cn(
                "border border-royal/20 rounded w-full bg-secondary"
              )}
            >
              <div className="flex items-center px-4 py-3 space-x-4 ">
                <Folder width={35} height="35" />
                <div>
                  <p className="text-lg text-bold text-neutral-50">
                    Uploading...
                  </p>
                  <p className="text-sm text-muted-foreground">
                    {upload.percentage}% -{" "}
                    {getUploadingMessage(upload.percentage)}
                  </p>
                </div>
              </div>
              <div className="relative mx-4 mb-4">
                <motion.div
                  style={{ width: `${upload.percentage}%` }}
                  className="absolute h-1 rounded bg-royal"
                />
                <div
                  style={{ backgroundColor: "#E4E4FF" }}
                  className="w-full h-1 rounded"
                />
              </div>
            </div>
            {error ? (
              <div className="p-4 mt-4 rounded-md bg-red-50">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <XCircleIcon
                      className="w-5 h-5 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">
                      Something went wrong with the upload
                    </h3>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        )}
        {!upload ? (
          <div className="my-4 mx-auto w-full">
            <div className="flex items-center justify-center space-x-2 w-full">
              <hr className={cn("flex-grow border-t")} />
              <div className="text-foreground/50">OR</div>
              <hr className={cn("flex-grow border-t")} />
            </div>
            <button
              onClick={() => setRecordOpen(true)}
              className="mt-4 mx-auto px-4 py-2 rounded-lg bg-secondary border w-full"
            >
              Record Video
            </button>
          </div>
        ) : null}
      </div>
      <div className="absolute top-0 right-0 p-2">
        <button
          onClick={onClose}
          className="p-4 text-neutral-700 rounded-full hover:text-foreground"
        >
          <Cross className="w-5" />
        </button>
      </div>
    </motion.div>
  );
};
