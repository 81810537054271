import { Spinner } from "ui/components/icons/Spinner";
import {
  CommandCenterMembersProgramProvider,
  useCommandCenterMembersProgram,
} from "../containers/members/program";
import { LineItem } from "../line-item";
import { MembersHeader } from "./index";

export const Component = () => {
  const {
    inputValue,
    setInputValue,
    filteredData,
    highlightedIndex,
    setHighlightedIndex,
    rowVirtualizer,
    parentRef,
    member,
    handleKeyDown,
    handleSetMemberProgram,
    isSaving,
  } = useCommandCenterMembersProgram();

  const { totalSize, virtualItems } = rowVirtualizer;

  return (
    <>
      <div id="command-center-header" className="shadow">
        <MembersHeader />
        <div className="relative border-b border-neutral-600">
          <input
            autoFocus
            onKeyDown={handleKeyDown}
            onChange={(e) => setInputValue(e.target.value)}
            value={inputValue}
            className="w-full p-4 text-xl bg-transparent focus:ring-0"
            placeholder={`Set ${member?.givenName || "..."}'s Program...`}
          />
          {isSaving ? (
            <div className="absolute top-6 right-4">
              <Spinner className="w-[20px] h-[20px] text-white" />
            </div>
          ) : null}
        </div>
      </div>
      <div
        // @ts-ignore
        ref={parentRef}
        style={{ overflow: "hidden auto" }}
        className="relative w-full"
      >
        <ul className="w-full shadow-md" style={{ height: totalSize }}>
          {virtualItems.map(({ size, start, index }) => {
            const program = filteredData[index];
            return (
              <LineItem
                key={`${program.name}${index}`}
                textToHighlight={program.name}
                data={program}
                option={program}
                size={size}
                start={start}
                searchWords={inputValue}
                highlighted={highlightedIndex === index}
                onMouseEnter={() => setHighlightedIndex(index)}
                handleClick={() => handleSetMemberProgram(program)}
                iconPosition="left"
                renderIcon={() => {
                  if (member?.bootcamp?.id === program.id) {
                    return (
                      <div className="w-5 h-5 rounded-full flex-center bg-neutral-600 group-hover:bg-neutral-500 shadow-input">
                        <div className="w-4 h-4 rounded-full bg-royal group-focus:bg-royal group-hover:bg-secondary shadow-button" />
                      </div>
                    );
                  }
                  return (
                    <div className="w-5 h-5 rounded-full bg-neutral-600 group-hover:bg-neutral-500 shadow-input" />
                  );
                }}
              />
            );
          })}
        </ul>
      </div>
    </>
  );
};

export const CommandCenterMembersProgramContext = () => {
  return (
    <CommandCenterMembersProgramProvider>
      <Component />
    </CommandCenterMembersProgramProvider>
  );
};
