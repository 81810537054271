import { cn } from "ui/lib/utils";

export const Select = ({
  options,
  value,
  onChange,
  placeholder = "Please Select",
  className,
  ...props
}) => {
  return (
    <select
      onChange={onChange}
      value={value}
      className={cn(
        "flex min-w-[70px] h-9 items-center justify-between rounded-md border border-input bg-transparent px-3 py-2 text-sm shadow-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-1 focus:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
        className
      )}
      {...props}
    >
      {placeholder ? (
        <option value="" disabled={!value}>
          {placeholder}
        </option>
      ) : null}
      {options.map(({ value, label }) => (
        <option key={value} value={value}>
          {label || value}
        </option>
      ))}
    </select>
  );
};
