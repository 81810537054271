import { getEquipment } from "queries/equipment/services";
import { useQuery } from "react-query";

export const useEquipment = (props = {}) => {
  const { data, error, isLoading } = useQuery(`/equipment`, getEquipment, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    ...props,
  });

  return {
    data,
    error,
    isLoading,
  };
};
