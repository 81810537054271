import * as Sentry from "@sentry/nextjs";
import {
  convertTagsForServer,
  updateMember,
} from "apollo/components/member/requests";
import { useMultiSelect } from "apollo/components/multi-select/container";
import { useCommandCenter } from "apollo/containers/command-center";
import sortBy from "lodash/sortBy";

import React, { useEffect, useMemo } from "react";
import useSWR from "swr";
import { useErrorNotification } from "ui/containers/error-notification";
import { useEditable } from "ui/lib/useEditable";
import useRequest from "ui/lib/useRequest";
import { createContainer } from "unstated-next";
import { getMemberRemoteOptions } from "../../helpers";

const getOptionValues = ({ id, data, ranking = [] }) => {
  if (!data) return [];
  if (!id) return [];
  if (!data[id]) return [];

  const options = new Set();
  let tags = data[id];

  if ("string" === typeof tags) {
    tags = data[id]
      .split(";")
      .map((n) => n.trim())
      .filter(Boolean);
  }
  tags.map((tag) => options.add(tag));

  return sortBy(
    [...options.values()].filter(Boolean).map((n) => ({ label: n, value: n })),
    (value) => {
      if (!ranking?.length) return value;
      return ranking.indexOf(value);
    }
  );
};

const sortJoin = (ar) => ar && ar.sort().join(",");

const useContainer = () => {
  const [hasChanges, setHasChanges] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);

  const { setItemsLength, getFromContext } = useCommandCenter();
  const memberId = useMemo(() => getFromContext("memberId"), [getFromContext]);

  const {
    data,
    error,
    isValidating,
    mutate: mutateTags,
  } = useSWR(`member-filters/tags`, getMemberRemoteOptions("tags"));

  const [selectedItems, setSelectedItems] = React.useState([]);
  const { activateError } = useErrorNotification();

  const { data: detailed } = useRequest(
    memberId && `users/detailed?userId=${memberId}`,
    {
      revalidateOnFocus: true,
    }
  );

  const initialSelectedItems = useMemo(
    () =>
      getOptionValues({
        data: detailed?.users[0] || [],
        id: "tags",
      }) || [],
    [detailed]
  );

  useEffect(() => {
    setSelectedItems(initialSelectedItems);
  }, [initialSelectedItems]);

  const updateDate = async (props) => {
    try {
      const initValues = initialSelectedItems.map((n) => n.value);
      const selectedValues = props.map((n) => n.value);
      const hasDifferences = sortJoin(selectedValues) !== sortJoin(initValues);
      setHasChanges(hasDifferences);
      if (!hasDifferences) return;

      setIsSaving(true);

      await updateMember(memberId, {
        tags: convertTagsForServer(selectedValues),
      });

      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
      activateError("Unable to save");
      console.error(error);
      Sentry.captureException(error);
    }
  };

  const { handleChange } = useEditable(initialSelectedItems, updateDate);

  useEffect(() => {
    handleChange(selectedItems);
  }, [selectedItems]);

  const handleCreateItem = (item) => {
    mutateTags((current) => {
      return [...current, item];
    }, false);
    setSelectedItems((curr) => [...curr, item]);
  };

  const removeSelectedItem = (item) => {
    setSelectedItems((current) =>
      current.filter((n) => n.value !== item.value)
    );
  };

  const multiSelectProps = useMultiSelect({
    onCreateItem: handleCreateItem,
    items: data || [],
    selectedItems,
    removeSelectedItem,
    onSelectedItemsChange: ({ selectedItems }) => {
      setSelectedItems((current) => {
        if (selectedItems.length < current.length) return current;
        return selectedItems;
      });
    },
  });

  useEffect(() => {
    setItemsLength(multiSelectProps.inputItems.length);
  }, [multiSelectProps.inputItems, setItemsLength]);

  return {
    ...multiSelectProps,
    removeSelectedItem,
    isSaving,
    memberId,
    selectedItems,
    hasChanges,
  };
};

export const {
  useContainer: useCommandCenterMembersTag,
  Provider: CommandCenterMembersTagProvider,
} = createContainer(useContainer);
