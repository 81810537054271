import * as Sentry from "@sentry/nextjs";
import React from "react";

const DefaultFallBack = () => (
  <div className="flex-center w-full h-full text-foreground">
    <h1>Something went wrong.</h1>
  </div>
);

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error({ error, errorInfo });
    Sentry.captureException(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      if (!this.props.fallback) {
        return <DefaultFallBack />;
      }
      return this.props.fallback;
    }

    return this.props.children;
  }
}
