import { MEMBERS } from "apollo/components/command-center/constants";
import { useCommandCenter } from "apollo/containers/command-center";
import { AnimatePresence, motion } from "framer-motion";
import React, { ReactNode, useRef } from "react";
import ReactDOM from "react-dom";
import { useIsomorphicLayoutEffect } from "react-use";
import { cn } from "ui/lib/utils";
import { MembersCommandCenterContext } from "./members";

const Modal = ({ children, isOpen }) => {
  if (!isOpen) return null;
  const container = document.getElementById("__next");
  return container ? ReactDOM.createPortal(children, container) : null;
};

const CommandCenterContext = React.memo(() => {
  const isServer = typeof window === "undefined";
  const { context } = useCommandCenter();

  if (isServer) return null;
  if (!context?.length) return null;

  switch (context[0].value) {
    case MEMBERS:
      return <MembersCommandCenterContext />;
    default:
      return null;
  }
});

CommandCenterContext.displayName = "CommandCenterContext";

export const Header = React.memo(
  ({ id, children }: { children?: (props: any) => ReactNode; id: string }) => {
    const { context, resetToRoot } = useCommandCenter();
    return (
      <div className="flex p-4 pb-0">
        <div className="px-2 py-1 space-x-2 text-xs rounded flex-center bg-secondary text-neutral-300">
          {context.map((data, ndx) => {
            if (data.key === id) {
              return (
                <div className="flex font-bold" key={data.key}>
                  {typeof children === "function" ? children(data) : null}
                  {ndx !== context.length - 1 ? (
                    <span className="ml-1">/</span>
                  ) : null}
                </div>
              );
            }
            return (
              <div key={data.key} className="flex">
                <button
                  onClick={() => ndx === 0 && resetToRoot()}
                  className={cn({
                    "cursor-pointer hover:text-neutral-50 font-bold":
                      data.key !== "ACTION" && ndx !== context.length - 1,
                    "cursor-default": ndx === context.length - 1,
                  })}
                >
                  {data.value}
                </button>
                {ndx !== context.length - 1 ? (
                  <span className="ml-1">/</span>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
);

Header.displayName = "Header";

export const CommandCenter = (): JSX.Element => {
  const { modalWidth, active, toggleActive, itemsLength, context } =
    useCommandCenter();
  const contentRef = useRef(null);
  const [headerHeight, setHeaderHeight] = React.useState(102);

  useIsomorphicLayoutEffect(() => {
    const elem = document.getElementById("command-center-header");
    if (!elem) return;

    setHeaderHeight(elem.clientHeight);
  }, [itemsLength]);

  const handleOverlayClick = (e) => {
    // @ts-ignore
    if (contentRef.current?.contains(e.target)) return;

    toggleActive();
  };

  return (
    <Modal isOpen={active}>
      {active ? (
        <div
          className="fixed inset-0 bg-background/70"
          style={{ zIndex: 500 }}
        />
      ) : null}
      <AnimatePresence>
        {active ? (
          <motion.div
            key="command-center"
            initial={"default"}
            animate={"active"}
            exit={"default"}
            transition={{
              type: "spring",
            }}
            variants={{
              default: {
                opacity: 0,
                scale: 0.9,
                y: -50,
                transition: { duration: 0.15 },
              },
              active: {
                opacity: 1,
                y: 0,
                scale: 1,
              },
            }}
            onClick={handleOverlayClick}
            style={{
              position: "fixed",
              willChange: "transform",
              transformOrigin: "top",
              perspective: "100px",
              inset: "0px",
              display: "flex",
              zIndex: 900,
              alignItems: "flex-start",
              WebkitBoxPack: "center",
              justifyContent: "center",
              padding: "10px 16px 16px",
            }}
          >
            <motion.div
              ref={contentRef}
              key="command-center-inner"
              className="border border-neutral-700/80 bg-background shadow-xl"
              animate={{
                height:
                  itemsLength >= 0 ? itemsLength * 46 + headerHeight : "auto",
              }}
              transition={{
                duration: 0.15,
              }}
              style={{
                width: "100%",
                opacity: 2,
                transform: "translate3d(0px, 0px, 0px)",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                flexShrink: 1,
                flexGrow: 1,
                minWidth: "0px",
                maxHeight: itemsLength >= 0 ? 620 : "100%",
                minHeight: context?.length === 1 ? 620 : 102,
                willChange: "transform",
                borderRadius: "8px",
                maxWidth: modalWidth,
                fontSize: "14px",
                color: "rgb(214, 214, 214)",
                overflow: "hidden",
                padding: 0,
              }}
            >
              <CommandCenterContext />
            </motion.div>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </Modal>
  );
};
