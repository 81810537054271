import localFont from "next/font/local";
import React from "react";

const SFProDisplayRegular = localFont({
  src: "../public/fonts/SFPro-Display-Regular.woff2",
  display: "swap",
  variable: "--sfpro-display-regular",
});
const SFProDisplayMedium = localFont({
  src: "../public/fonts/SFPro-Display-Medium.woff2",
  display: "swap",
  variable: "--sfpro-display-medium",
});
const SFProDisplayBold = localFont({
  src: "../public/fonts/SFPro-Display-Bold.woff2",
  display: "swap",
  variable: "--sfpro-display-bold",
});
const SFProDisplayHeavy = localFont({
  src: "../public/fonts/SFPro-Display-Heavy.woff2",
  display: "swap",
  variable: "--sfpro-display-heavy",
});
const DrukBold = localFont({
  src: "../public/fonts/Druk-Bold.otf",
  display: "swap",
  variable: "--druk-bold",
});
const DrukWideBold = localFont({
  src: "../public/fonts/druk-wide-bold.ttf",
  display: "swap",
  variable: "--druk-wide-bold",
});

export const FontProvider = ({ children }) => {
  return (
    <>
      <style jsx global>{`
        html {
          --sfpro-display-regular: ${SFProDisplayRegular.style.fontFamily};
          --sfpro-display-medium: ${SFProDisplayMedium.style.fontFamily};
          --sfpro-display-bold: ${SFProDisplayBold.style.fontFamily};
          --sfpro-display-heavy: ${SFProDisplayHeavy.style.fontFamily};
          --druk-bold: ${DrukBold.style.fontFamily};
          --druk-wide-bold: ${DrukWideBold.style.fontFamily};
        }
      `}</style>
      {children}
    </>
  );
};
