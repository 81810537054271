import { useState } from "react";
import { createContainer } from "unstated-next";

const useContainer = () => {
  const [copiedId, setCopiedId] = useState<string | undefined | null>();
  const [keepVOs, setKeepVOs] = useState<boolean>(false);
  const [moveId, setMoveId] = useState<string | undefined | null>();

  return {
    moveId,
    setMoveId,
    keepVOs,
    setKeepVOs,
    copiedId,
    setCopiedId,
  };
};

export const {
  useContainer: useCalendarEvent,
  Provider: CalendarEventProvider,
} = createContainer(useContainer);
