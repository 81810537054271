import * as React from "react";

export function TeamsIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.716 6.947a3.21 3.21 0 003.194-3.194c0-1.75-1.443-3.186-3.194-3.186a3.2 3.2 0 00-3.193 3.186 3.206 3.206 0 003.193 3.194zM3.4 12.44a3.21 3.21 0 003.193-3.193C6.592 7.503 5.15 6.06 3.4 6.06A3.2 3.2 0 00.206 9.247a3.2 3.2 0 003.193 3.193zm10.65 0c1.742 0 3.185-1.443 3.185-3.193 0-1.744-1.443-3.186-3.186-3.186-1.765 0-3.2 1.442-3.2 3.186a3.207 3.207 0 003.2 3.193zm-5.333 5.493a3.21 3.21 0 003.194-3.193c0-1.743-1.443-3.186-3.194-3.186a3.204 3.204 0 00-3.193 3.186 3.206 3.206 0 003.193 3.193z"
        fill="currentColor"
      />
    </svg>
  );
}
