"use client";

import {
  CheckIcon,
  CopyIcon,
  MoonIcon,
  ResetIcon,
  SunIcon,
} from "@radix-ui/react-icons";
import template from "lodash.template";
import * as React from "react";

import { Dialog, DialogContent } from "ui/components/primitives/dialog";
import { Label } from "ui/components/primitives/label";
import { Skeleton } from "ui/components/primitives/skeleton";
import { useBodyCSSVariable } from "ui/hooks/use-body-css-vars";
import { config as initialConfig, useConfig } from "ui/hooks/use-config";
import { cn } from "ui/lib/utils";
import { themes } from "ui/themes";

import { useCurrentUser } from "queries/current-user";
import { TooltipProvider } from "ui/components/primitives/tooltip";
import { useKeyDown } from "ui/hooks/listeners";
import { Button } from "./primitives/button";
import { ScrollArea } from "./primitives/scroll-area";
import { ThemeCombobox } from "./theme-combobox";
import { useThemeMode } from "./theme-mode-provider";
import { ThemeWrapper } from "./theme-wrapper";

export function ThemeCustomizer() {
  const [open, setOpen] = React.useState(false);
  const { isAdmin } = useCurrentUser();
  // cmd + J
  useKeyDown(
    74,
    (e) => {
      if (e.metaKey && isAdmin) {
        e.preventDefault();
        setOpen(true);
      }
    },
    [isAdmin]
  );

  if (!isAdmin) return null;

  return (
    <TooltipProvider>
      <div className="flex items-center space-x-2">
        <Dialog open={open} onOpenChange={setOpen}>
          <DialogContent className="h-full md:h-auto md:w-[420px] p-6 pt-10">
            <Customizer />
          </DialogContent>
        </Dialog>
      </div>
    </TooltipProvider>
  );
}

function hslToHex(h, s, l) {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0"); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

function hexToHSL(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) return;

  let r = parseInt(result[1], 16);
  let g = parseInt(result[2], 16);
  let b = parseInt(result[3], 16);

  (r /= 255), (g /= 255), (b /= 255);
  let max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max == min) {
    h = s = 0; // achromatic
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }

    h /= 6;
  }

  h = Math.round(h * 360);
  s = Math.round(s * 100);
  l = Math.round(l * 100);

  return { h, s, l };
}

function Customizer() {
  const [mounted, setMounted] = React.useState(false);
  const [config, setConfig] = useConfig();
  const activeTheme = themes.find((theme) => theme.name === config.theme);
  const { theme: mode = "dark", setTheme: setMode } = useThemeMode();
  const { setVariable, resetVariables } = useBodyCSSVariable();

  React.useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <ThemeWrapper
      defaultTheme="zinc"
      className="flex flex-col space-y-4 md:space-y-6"
    >
      <div className="flex items-start">
        <div className="space-y-1 pr-2">
          <div className="font-semibold leading-none tracking-tight">
            Customize
          </div>
          <div className="text-xs text-muted-foreground">
            Pick a style and color for your components.
          </div>
        </div>
        <Button
          variant="ghost"
          size="icon"
          className="ml-auto rounded-[0.5rem]"
          onClick={() => {
            setConfig(initialConfig);
            resetVariables();
          }}
        >
          <ResetIcon />
          <span className="sr-only">Reset</span>
        </Button>
      </div>
      <div className="flex flex-1 flex-col space-y-4 md:space-y-6">
        <div className="space-y-1.5">
          <Label className="text-xs">Theme</Label>
          <div>
            <ThemeCombobox resetVariables={resetVariables} />
          </div>
        </div>
        <ScrollArea className="space-y-1.5 max-h-[240px]">
          <Label className="text-xs">Color</Label>
          <div className="grid grid-cols-2 gap-2">
            {activeTheme &&
              activeTheme?.cssVars?.[mode] &&
              Object.keys(activeTheme?.cssVars[mode]).map((key) => {
                if (key === "radius") return null;

                const value = activeTheme?.cssVars[mode][key];
                const [h, s, l] = value
                  .split(" ")
                  .map((n) => parseFloat(n.replace("%", "")));
                return mounted ? (
                  <Button
                    key={key}
                    variant={"outline"}
                    size="sm"
                    className={cn("justify-start text-left relative")}
                  >
                    <div className="rounded-full shrink-0 -translate-x-1 mr-1 overflow-hidden">
                      <input
                        className={cn(
                          "flex h-5 w-5 items-center justify-center rounded-full border-none",
                          `bg-${key}`
                        )}
                        type="color"
                        value={hslToHex(h, s, l)}
                        onChange={(e) => {
                          const value = hexToHSL(e.target.value);
                          if (!value) return;
                          const { h, s, l } = value;
                          setVariable(`--${key}`, `${h} ${s}% ${l}%`);
                        }}
                      />
                    </div>

                    {key}
                  </Button>
                ) : (
                  <Skeleton className="h-8 w-full" key={key} />
                );
              })}
          </div>
        </ScrollArea>
        <div className="space-y-1.5">
          <Label className="text-xs">Radius</Label>
          <div className="grid grid-cols-5 gap-2">
            {["0", "0.3", "0.5", "0.75", "1.0"].map((value) => {
              return (
                <Button
                  variant={"outline"}
                  size="sm"
                  key={value}
                  data-radius={config.radius}
                  className={cn(
                    parseFloat(value) === config.radius &&
                      "border-2 border-primary"
                  )}
                  onClick={() => {
                    setVariable("--radius", `${value}rem`);
                    setConfig({
                      ...config,
                      radius: parseFloat(value),
                    });
                  }}
                >
                  {value}
                </Button>
              );
            })}
          </div>
        </div>
        <div className="space-y-1.5">
          <Label className="text-xs">Mode</Label>
          <div className="grid grid-cols-2 gap-2">
            {mounted ? (
              <>
                <Button
                  variant={"outline"}
                  size="sm"
                  onClick={() => setMode("light")}
                  className={cn(mode === "light" && "border-2 border-primary")}
                >
                  <SunIcon className="mr-1 -translate-x-1" />
                  Light
                </Button>
                <Button
                  variant={"outline"}
                  size="sm"
                  onClick={() => setMode("dark")}
                  className={cn(mode === "dark" && "border-2 border-primary")}
                >
                  <MoonIcon className="mr-1 -translate-x-1" />
                  Dark
                </Button>
              </>
            ) : (
              <>
                <Skeleton className="h-8 w-full" />
                <Skeleton className="h-8 w-full" />
              </>
            )}
          </div>
        </div>
      </div>
      <CopyCodeButton />
    </ThemeWrapper>
  );
}

function CopyCodeButton() {
  const [config] = useConfig();
  const [hasCopied, setHasCopied] = React.useState(false);

  const activeTheme = themes.find((theme) => theme.name === config.theme);

  React.useEffect(() => {
    setTimeout(() => {
      setHasCopied(false);
    }, 2000);
  }, [hasCopied]);

  return activeTheme ? (
    <Button
      onClick={() => {
        getThemeCode(activeTheme, config.radius);
        setHasCopied(true);
      }}
    >
      {hasCopied ? (
        <CheckIcon className="mr-2 h-4 w-4" />
      ) : (
        <CopyIcon className="mr-2 h-4 w-4" />
      )}
      Copy
    </Button>
  ) : null;
}

function getThemeCode(theme: any, radius: number) {
  if (!theme) {
    return "";
  }

  return template(BASE_STYLES_WITH_VARIABLES)({
    colors: theme.cssVars,
    radius,
  });
}

const BASE_STYLES_WITH_VARIABLES = `
@layer base {
  :root {
    --background: <%- colors.light["background"] %>;
    --foreground: <%- colors.light["foreground"] %>;
    --card: <%- colors.light["card"] %>;
    --card-foreground: <%- colors.light["card-foreground"] %>;
    --popover: <%- colors.light["popover"] %>;
    --popover-foreground: <%- colors.light["popover-foreground"] %>;
    --primary: <%- colors.light["primary"] %>;
    --primary-foreground: <%- colors.light["primary-foreground"] %>;
    --secondary: <%- colors.light["secondary"] %>;
    --secondary-foreground: <%- colors.light["secondary-foreground"] %>;
    --muted: <%- colors.light["muted"] %>;
    --muted-foreground: <%- colors.light["muted-foreground"] %>;
    --accent: <%- colors.light["accent"] %>;
    --accent-foreground: <%- colors.light["accent-foreground"] %>;
    --destructive: <%- colors.light["destructive"] %>;
    --destructive-foreground: <%- colors.light["destructive-foreground"] %>;
    --border: <%- colors.light["border"] %>;
    --input: <%- colors.light["input"] %>;
    --ring: <%- colors.light["ring"] %>;
    --radius: <%- radius %>rem;
  }
 
  .dark {
    --background: <%- colors.dark["background"] %>;
    --foreground: <%- colors.dark["foreground"] %>;
    --card: <%- colors.dark["card"] %>;
    --card-foreground: <%- colors.dark["card-foreground"] %>;
    --popover: <%- colors.dark["popover"] %>;
    --popover-foreground: <%- colors.dark["popover-foreground"] %>;
    --primary: <%- colors.dark["primary"] %>;
    --primary-foreground: <%- colors.dark["primary-foreground"] %>;
    --secondary: <%- colors.dark["secondary"] %>;
    --secondary-foreground: <%- colors.dark["secondary-foreground"] %>;
    --muted: <%- colors.dark["muted"] %>;
    --muted-foreground: <%- colors.dark["muted-foreground"] %>;
    --accent: <%- colors.dark["accent"] %>;
    --accent-foreground: <%- colors.dark["accent-foreground"] %>;
    --destructive: <%- colors.dark["destructive"] %>;
    --destructive-foreground: <%- colors.dark["destructive-foreground"] %>;
    --border: <%- colors.dark["border"] %>;
    --input: <%- colors.dark["input"] %>;
    --ring: <%- colors.dark["ring"] %>;
  }
}
`;
