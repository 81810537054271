import { useCommandCenter } from "apollo/containers/command-center";
import { format } from "date-fns";
import { useEffect, useMemo } from "react";
import { toast } from "react-hot-toast";
import { getValidDate } from "ui/lib/helpers";
import { handleUpdateRequest } from "ui/lib/rockyFetcher";
import { useEditable } from "ui/lib/useEditable";
import useRequest from "ui/lib/useRequest";
import { createContainer } from "unstated-next";

const useContainer = ({ columnId } = { columnId: "" }) => {
  const { setItemsLength, context, getFromContext, setModalWidth } =
    useCommandCenter();

  useEffect(() => {
    setModalWidth(420);
    setItemsLength(-1);

    return () => {
      setModalWidth(640);
    };
  }, []);

  const memberId = useMemo(() => getFromContext("memberId"), [context]);

  const { data: detailed } = useRequest(
    memberId && `users/detailed?userId=${memberId}`
  );
  const [user] = detailed?.users || [];

  const { request } = handleUpdateRequest();

  const updateDate = async (value) => {
    if (!request) return;
    const setUserTrialEnd = request(
      "/setUserTrialEnd",
      {
        userID: memberId,
        trialEndTSStr: value,
      },
      "post"
    );

    toast.promise(
      setUserTrialEnd,
      {
        loading: "Loading...",
        error: "Something went wrong, please try again",
        success: `Date has been updated to ${format(
          getValidDate(value),
          "MMM d, y"
        )}`,
      },
      {
        position: "top-center",
      }
    );
  };

  const {
    error,
    isSaving,
    value: editableValue,
    handleChange,
  } = useEditable(user?.[columnId], updateDate);

  return {
    user,
    value: editableValue,
    updateDate: handleChange,
    isSaving,
    error,
  };
};

export const {
  useContainer: useCommandCenterMembersDate,
  Provider: CommandCenterMembersDateProvider,
} = createContainer(useContainer);
