import { useCombobox, useMultipleSelection } from "downshift";
import { matchSorter } from "match-sorter";
import React from "react";
import { useDeepCompareEffect } from "react-use";

function defaultOptionFilterFunc(
  items,
  inputValue
): {
  value: string;
  label: string;
}[] {
  return matchSorter(items, inputValue, { keys: ["value", "label"] });
}

function defaultItemRenderer(selected) {
  return selected.label;
}

export const useMultiSelect = ({
  items = [],
  optionFilterFunc = defaultOptionFilterFunc,
  itemRenderer = defaultItemRenderer,
  onCreateItem,
  selectedItems = [],
  onSelectedItemsChange,
  removeSelectedItem,
}: any) => {
  const [isCreating, setIsCreating] = React.useState(false);
  const [inputItems, setInputItems] = React.useState(items);
  const disclosureRef = React.useRef(null);

  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    activeIndex,
  } = useMultipleSelection({
    onSelectedItemsChange,
    selectedItems,
    stateReducer: (_, actionAndChanges) => {
      const { type, changes } = actionAndChanges;
      switch (type) {
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          return {
            ...changes,
            activeIndex: undefined,
          };
        default:
          return changes;
      }
    },
  });

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    openMenu,
    selectItem,
    setHighlightedIndex,
    inputValue,
  } = useCombobox({
    initialIsOpen: true,
    selectedItem: null,
    items: inputItems,
    onInputValueChange: ({ inputValue }) => {
      const filteredItems = optionFilterFunc(items, inputValue || "");

      if (isCreating && filteredItems.length > 0) {
        setIsCreating(false);
      }

      setHighlightedIndex(0);
      setInputItems(filteredItems);
    },
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            highlightedIndex: state.highlightedIndex,
            inputValue: "",
          };
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            highlightedIndex: state.highlightedIndex,
            isOpen: true,
            inputValue: "",
          };
        default:
          return changes;
      }
    },
    onStateChange: ({ type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (selectedItem) {
            // @ts-ignore
            if (selectedItemValues.includes(selectedItem.value)) {
              removeSelectedItem(selectedItem);
            } else {
              if (typeof onCreateItem === "function" && isCreating) {
                onCreateItem(selectedItem);
                setIsCreating(false);
                setInputItems([
                  selectedItem,
                  // @ts-ignore
                  ...items.filter(({ value }) => value !== selectedItem.value),
                ]);
              } else {
                addSelectedItem(selectedItem);
              }
            }

            selectItem(null);
          }
          break;
        default:
          break;
      }
    },
  });

  React.useEffect(() => {
    if (typeof onCreateItem !== "function") return;

    if (inputItems.length === 0 && inputValue.length > 0) {
      setIsCreating(true);
      setInputItems([{ label: `${inputValue}`, value: inputValue }]);
      setHighlightedIndex(0);
    }
  }, [
    inputItems,
    setIsCreating,
    setHighlightedIndex,
    inputValue,
    onCreateItem,
  ]);

  useDeepCompareEffect(() => {
    setInputItems(items);
  }, [items]);

  // useEffect(() => {
  //   setHighlightedIndex(0);
  // }, [items]);

  const selectedItemValues = selectedItems.map((item) => item.value);

  return {
    setInputItems,
    inputItems,
    isCreating,
    selectedItemValues,
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    openMenu,
    selectItem,
    setHighlightedIndex,
    inputValue,
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    itemRenderer,
    activeIndex,
    disclosureRef,
  };
};
