// This hook allows a component to control a value that can be edited by the user, with the changes being saved remotely.
// If the remote save fails, the value is reset to its initial value, and an error is displayed to the user.

import * as Sentry from "@sentry/nextjs";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useState } from "react";
import { useErrorNotification } from "ui/containers/error-notification";

// Helper function to check if the argument is an event
function isEvent(e) {
  if (e instanceof Event) return true;
  if (e && e.nativeEvent instanceof Event) return true;
  if (e?.target?.value) return true;
  return false;
}

// The useEditable hook. It takes two arguments:
// an initialValue for the value, and a function to handle updating the value remotely.
export const useEditable = (
  initialValue: any = "",
  handleUpdateRemoteValue: (value: any) => Promise<void>
) => {
  // Local states for the value, saving status, error status, and active edit status
  const [value, setValue] = useState(initialValue);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(false);
  const [editActive, setEditActive] = useState(false);

  // Hook to handle error notifications
  const { activateError } = useErrorNotification();

  // Function to save value, handling any errors that occur
  const saveValue = async (value) => {
    try {
      setIsSaving(true);
      await handleUpdateRemoteValue(value);
      setIsSaving(false);
    } catch (error) {
      console.error(error);
      setError(true);
      setIsSaving(false);
      setValue(initialValue);
      activateError("Unable to save");
      Sentry.captureException(error);
    }
  };

  // Debounced version of saveValue, will only be invoked once per 1000ms
  const debouncedSave = debounce(saveValue, 1000);

  // Effect hook to set the value when the initialValue changes
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  // Effect hook to cancel the debounced save when the component unmounts
  useEffect(() => {
    return () => debouncedSave.cancel();
  }, []);

  // Callback to handle changes, saving the new value both locally and remotely
  const handleChange = useCallback((e) => {
    const newValue = isEvent(e) ? e.target.value || "" : e;
    setValue(newValue);
    debouncedSave(newValue);
  }, []);

  // Functions to activate and deactivate edit mode
  const activateEdit = () => setEditActive(true);
  const deactivateEdit = () => setEditActive(false);

  // Return the current value and utility functions
  return {
    value: value || "",
    error,
    isSaving,
    handleChange,
    setValue,
    editActive,
    activateEdit,
    deactivateEdit,
  };
};
