import { request } from "graphql-request";
import { jsonToGraphQLQuery } from "json-to-graphql-query";
import { getSession } from "ui/containers/session";

export const getDetailedUsersDistinctData = async ({
  membershipStatuses,
  roleNames,
  flagTypes,
  subscriptionStatuses,
  subscriptionTypes,
  tags,
  utmData,
} = {}) => {
  const session = getSession();
  const result = await request(
    process.env.NEXT_PUBLIC_API_BASE_URL_ROCKY_GRAPHQL,
    jsonToGraphQLQuery(
      {
        query: {
          detailedUsersDistinctData: {
            membershipStatuses,
            roleNames,
            flagTypes,
            subscriptionStatuses,
            subscriptionTypes,
            tags,
            ...(utmData && {
              utmData: {
                sources: utmData.sources,
                contents: utmData.contents,
                campaigns: utmData.campaigns,
                mediums: utmData.mediums,
                terms: utmData.terms,
                types: utmData.types,
              },
            }),
          },
        },
      },
      { pretty: true }
    ),
    {},
    {
      Authorization: `Bearer ${session}`,
      "Content-Type": "application/json",
    }
  );

  return result;
};
