import { useEffect, useState } from "react";

export function useBrowserType() {
  const [isFirefox, setIsFirefox] = useState(false);
  const [isSafari, setIsSafari] = useState(false);
  const [isIE, setIsIE] = useState(false);
  const [isEdge, setIsEdge] = useState(false);
  const [isChrome, setIsChrome] = useState(false);
  const [isBrave, setIsBrave] = useState(false);

  useEffect(() => {
    if (!process.browser) return;
    setIsFirefox(typeof InstallTrigger !== "undefined");

    // Safari 3.0+ "[object HTMLElementConstructor]"
    setIsSafari(
      /constructor/i.test(window.HTMLElement) ||
        (function (p) {
          return p.toString() === "[object SafariRemoteNotification]";
        })(
          !window["safari"] ||
            // eslint-disable-next-line no-undef
            (typeof safari !== "undefined" && safari.pushNotification)
        )
    );

    // Internet Explorer 6-11
    setIsIE(!!document.documentMode);

    // Edge 20+
    setIsEdge(!isIE && !!window.StyleMedia);

    // Chrome 1 - 71
    setIsChrome(
      (!!window.chrome &&
        (!!window.chrome.webstore || !!window.chrome.runtime)) ||
        (/Chrome/.test(navigator.userAgent) &&
          /Google Inc/.test(navigator.vendor))
    );

    navigator.brave?.isBrave?.()?.then?.(setIsBrave);
  }, [process.browser]);

  return {
    isFirefox,
    isSafari,
    isEdge,
    isChrome,
    isBrave,
  };
}
