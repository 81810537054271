import { useCommandCenter } from "apollo/containers/command-center";
import { useCurrentUser } from "queries/current-user";
import React from "react";
import useRequest from "ui/lib/useRequest";
import { createContainer } from "unstated-next";

const useContainer = () => {
  const { isAdmin } = useCurrentUser();
  const { context, getFromContext } = useCommandCenter();
  const memberId = React.useMemo(() => getFromContext("memberId"), [context]);

  const { data: member, mutate: mutateMember } = useRequest(
    memberId && `users/${memberId}${isAdmin ? "/sensitive" : ""}`
  );

  return { member, memberId, mutateMember };
};

export const {
  useContainer: useCommandCenterMembers,
  Provider: CommandCenterMembersProvider,
} = createContainer(useContainer);
