import { AxiosRequestConfig } from "axios";
import { User } from "ui/types/user";
import { apiClient } from "../constants";

export const getCurrentUser =
  (session: string | null, config: AxiosRequestConfig = {}) =>
  async (): Promise<User> => {
    const response = await apiClient.get<User>("/users/me", {
      headers: {
        Authorization: `Bearer ${session}`,
      },
      ...config,
    });

    return response.data;
  };
