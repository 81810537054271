import { useState } from "react";
import { createContainer } from "unstated-next";

const useContainer = () => {
  const [modalActive, setModalActive] = useState(false);

  const toggleModalActive = () => setModalActive((c) => !c);

  return {
    modalActive,
    setModalActive,
    toggleModalActive,
  };
};

export const { useContainer: useModal, Provider: ModalProvider } =
  createContainer(useContainer);
