export const ACTIONS = {
  MEMBER: {
    EDIT_TAGS: "Edit Tags",
    EDIT_NOTES: "Edit Notes",
    EDIT_TRIAL_EXPIRATION_DATE: "Edit Trial Expiration Date",
    UPDATE_PROGRAM: "Update Program",
    UPDATE_STATUS_FREE: "Make Member Free",
    UPDATE_FEATURE_FLAGS: "Update Feature Flags",
    OPEN_CHAT: "Open Chat",
    OPEN_PROFILE: "Open Profile",
    OPEN_ENTITLEMENTS: "Open Entitlements",
    OPEN_ENTITLEMENT_CHANGE_TEAM: "Open Change Team Entitlement",
    AMPLITUDE: "Open Amplitude",
    COPY_ID: "Copy ID",
    SCRAMBLE_USER: "Scrable",
    UNSCRAMBLE_USER: "Unscrable",
  },
};

export const MEMBERS = "Members";
