import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React from "react";
import { VersionTracker } from "./version-tracker";

const Navigation = dynamic(
  () => import("./navigation").then((mod) => ({
    default: mod.Navigation
  })),
  {
    ssr: false,
    placeholder: <div className="h-24">hello</div>,
  }
);
const TeamsDropdown = dynamic(
  () => import("./teams-dropdown").then((mod) => ({
    default: mod.TeamsDropdown
  })),
  {
    ssr: false,
  }
);
const UserDropdown = dynamic(
  () => import("./user-dropdown").then((mod) => ({
    default: mod.UserDropdown
  })),
  {
    ssr: false,
  }
);

const Logo = React.memo(() => {
  return (
    <img
      className="hidden w-auto h-7 md:block"
      src={`/assets/logo/white-icon.svg`}
      alt="Apollo Logo"
    />
  );
});

export const Header = React.memo(() => {
  const { pathname, asPath, push, query } = useRouter();

  const handleLogoClick = React.useCallback(() => {
    push("/programs");
  }, [pathname, asPath]);

  return (
    <nav
      key={query?.bootcampId}
      id="top-navigation"
      className="sticky top-0 z-50 hidden bg-background border-b md:block"
    >
      <div className="hidden md:block w-full items-center justify-center">
        <div className="w-full px-4 py-2">
          <div className="flex-center relative space-x-2 w-full min-h-[44px]">
            <div className="flex-center absolute left-0 px-1">
              <button
                onClick={handleLogoClick}
                className="rounded flex items-center flex-shrink-0"
              >
                <Logo />
              </button>
              <div aria-hidden="true" className="flex flex-[0 0 auto]">
                <svg
                  data-testid="geist-icon"
                  fill="none"
                  height="32"
                  shapeRendering="geometricPrecision"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                  viewBox="0 0 24 24"
                  width="32"
                  className="text-neutral-600"
                >
                  <path d="M16.88 3.549L7.12 20.451"></path>
                </svg>
              </div>
              <TeamsDropdown />
            </div>
            <Navigation />
            <div className="absolute right-0 flex items-center pr-2 md:ml-6 md:pr-0">
              <VersionTracker>
                <UserDropdown />
              </VersionTracker>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
});
