import { useCallback } from "react";
import toast from "react-hot-toast";

export const useErrorNotification = () => {
  const activateError = useCallback((message) => toast.error(message), []);

  return {
    activateError,
  };
};
