import { useQuery } from "react-query";
import { useSession } from "ui/containers/session";
import { fetcher } from "ui/lib/misc";

export const getMemberByID = (memberID, session) => async () => {
  if (!memberID) return null;
  const url = `${process.env.NEXT_PUBLIC_API_BASE_URL}/users/${memberID}/sensitive`;
  const response = await fetcher(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${session}`,
    },
  });

  return response;
};

export const useMemberSensitive = (memberID, initialData) => {
  const { session } = useSession();
  const { data, error, isLoading, isFetching, refetch } = useQuery(
    [`member`, `sensitive`, memberID],
    getMemberByID(memberID, session),
    {
      initialData,
      enabled: !!memberID && !!session,
      refetchOnMount: false,
      staleTime: 60000
    }
  );

  return {
    data,
    error,
    refetch,
    isLoading,
    isFetching,
  };
};
