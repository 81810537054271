import { useEffect, useRef } from "react";

type SpacebarPressCallbacks = {
  onKeyPress?: () => Promise<void> | void;
  onHold?: () => Promise<void> | void;
  onRelease?: () => Promise<void> | void;
  holdDelay?: number; // delay in milliseconds to consider as a hold
};

export const useSpacebarPress = ({
  onKeyPress,
  onHold,
  onRelease,
  holdDelay = 300,
}: SpacebarPressCallbacks) => {
  const holdTimer = useRef<NodeJS.Timeout | null>(null);
  const isHeld = useRef<boolean>(false);
  const holdTriggered = useRef<boolean>(false);

  useEffect(() => {
    const handleKeyDown = async (e: KeyboardEvent) => {
      const activeElement = document.activeElement;
      if (
        e.code === "Space" &&
        !(
          activeElement instanceof HTMLInputElement ||
          activeElement instanceof HTMLTextAreaElement
        )
      ) {
        e.preventDefault(); // Prevent the default spacebar action (page scroll)
        if (!isHeld.current) {
          isHeld.current = true;

          // Set hold timer
          holdTimer.current = setTimeout(async () => {
            holdTriggered.current = true;
            await onHold?.();
          }, holdDelay);
        }
      }
    };

    const handleKeyUp = async (e: KeyboardEvent) => {
      const activeElement = document.activeElement;
      if (
        e.code === "Space" &&
        !(
          activeElement instanceof HTMLInputElement ||
          activeElement instanceof HTMLTextAreaElement
        )
      ) {
        isHeld.current = false;
        if (holdTimer.current) {
          clearTimeout(holdTimer.current);
        }
        if (!holdTriggered.current) {
          await onKeyPress?.();
        }
        await onRelease?.();
        holdTriggered.current = false; // Reset for the next press
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
      if (holdTimer.current) {
        clearTimeout(holdTimer.current);
      }
    };
  }, [onKeyPress, onHold, onRelease, holdDelay]);

  return null; // This hook doesn't need to return anything
};
