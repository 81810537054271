import * as dateFns from "date-fns";
import { useCallback, useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { Spinner } from "ui/components/icons/Spinner";
import { fn, getValidDate } from "ui/lib/helpers";
import { cn } from "ui/lib/utils";

const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const THEME = {
  light: 0,
  dark: 1,
};

export const DateSelector = ({
  loading = false,
  currentDate = new Date(),
  handleSelect = (date, key) => null,
  onChangeMonth = fn,
  dailyEvents = {},
  defaultEventTooltipText = "",
  rangeEnabled = false,
  selectedRangeDate = false,
  theme = THEME.light,
}) => {
  const [hovered, setHovered] = useState();
  const [activeMonth, setActiveMonth] = useState(getValidDate(currentDate));
  const [month, setMonth] = useState(getValidDate(currentDate).getMonth());
  const [year, setYear] = useState(getValidDate(currentDate).getFullYear());
  const [noOfDays, setNoOfDays] = useState([]);
  const [blankdays, setBlankdays] = useState([]);

  const getNoOfDays = () => {
    let daysInMonth = dateFns.getDaysInMonth(activeMonth);
    // find where to start calendar day of week
    let dayOfWeek = new Date(year, month).getDay();
    let blankdaysArray = [];
    for (let i = 1; i <= dayOfWeek; i++) {
      blankdaysArray.push(i);
    }
    let daysArray = [];
    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push(i);
    }
    setBlankdays(blankdaysArray);
    setNoOfDays(daysArray);
  };

  useEffect(() => {
    setMonth(activeMonth.getMonth());
    setYear(activeMonth.getFullYear());
  }, [activeMonth]);

  useEffect(() => {
    getNoOfDays();
  }, [month]);

  const getDateFromDay = useCallback(
    (day) => {
      return new Date(year, month, day);
    },
    [year, month]
  );

  const isActiveDate = (date) => {
    let rangeDateActive;
    const currentDateActive = dateFns.isSameDay(date, currentDate);
    if (dateFns.isDate(selectedRangeDate)) {
      rangeDateActive = dateFns.isSameDay(date, selectedRangeDate);
    }
    return currentDateActive || rangeDateActive;
  };

  const getFormattedDate = (date) => {
    return dateFns.format(date, "yyyy-MM-dd");
  };

  const isWithinRange = (date) => {
    return (
      dateFns.isAfter(date, currentDate) &&
      dateFns.isBefore(date, hovered || selectedRangeDate)
    );
  };
  const isFirstDayOfRange = (date) => {
    return dateFns.isSameDay(dateFns.addDays(currentDate, 1), date);
  };
  const isLastDayOfRange = (date) => {
    return dateFns.isSameDay(hovered || selectedRangeDate, date);
  };

  const handleChangeMonth = (val) => {
    let newDate = dateFns[val > 0 ? "addMonths" : "subMonths"](
      new Date(year, month, 1),
      Math.abs(val)
    );
    setActiveMonth(newDate);
    onChangeMonth(newDate);
  };

  const dateEnabled = (date) => {
    if (!rangeEnabled) return true;
    return !dateFns.isBefore(date, currentDate);
  };

  const onHandleSelect = (date, key) => {
    if (!rangeEnabled) return handleSelect(date, key);

    if (dateFns.isBefore(date, currentDate)) return;
    handleSelect(date, key);
  };

  return (
    <div>
      {loading ? (
        <>
          <div
            className="absolute inset-0 z-10 m-auto"
            style={{ backgroundColor: "rgb(0 0 0 / 50%)" }}
          />
          <div className="absolute inset-0 z-20 m-auto flex-center">
            <Spinner className="w-[25px] h-[25px] text-white" />
          </div>
        </>
      ) : null}
      <div className="flex items-center justify-between px-1 mb-2">
        <div>
          <span
            className={cn("text-lg font-bold", {
              "text-neutral-800": theme === THEME.light,
              "text-foreground": theme === THEME.dark,
            })}
          >
            {MONTH_NAMES[month]}
          </span>
          <span
            className={cn("ml-1 text-lg font-normal", {
              "text-neutral-600": theme === THEME.light,
              "text-muted-foreground": theme === THEME.dark,
            })}
          >
            {year}
          </span>
        </div>
        <div>
          <button
            type="button"
            className="inline-flex p-1 transition duration-100 ease-in-out rounded-full cursor-pointer focus:outline-none focus:ring hover:bg-neutral-200"
            onClick={() => handleChangeMonth(-1)}
          >
            <svg
              className={cn("inline-flex w-4 h-4 text-neutral-500")}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <button
            type="button"
            className="inline-flex p-1 transition duration-100 ease-in-out rounded-full cursor-pointer focus:outline-none focus:ring hover:bg-neutral-200"
            onClick={() => handleChangeMonth(1)}
          >
            <svg
              className={cn("inline-flex w-4 h-4 text-neutral-500")}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
      </div>

      <div className="flex flex-wrap mb-3 -mx-1">
        {DAYS.map((day, index) => {
          return (
            <div key={day} style={{ width: "14.26%" }} className="px-1">
              <div
                className={cn("text-xs font-medium text-center select-none", {
                  "text-neutral-800": theme === THEME.light,
                  "text-foreground": theme === THEME.dark,
                })}
              >
                {day}
              </div>
            </div>
          );
        })}
      </div>

      <div className="flex flex-wrap -mx-1">
        {blankdays.map((blankday, ndx) => {
          return (
            <div
              key={ndx}
              style={{ width: "14.28%" }}
              className="p-1 text-sm text-center border border-transparent"
            ></div>
          );
        })}
        <ReactTooltip key={activeMonth} />
        {noOfDays.map((day, dateIndex) => {
          const date = getDateFromDay(day);
          const key = getFormattedDate(date);
          const isActive = isActiveDate(date);
          const isInRange = isWithinRange(date);
          const firstDayOfRange = isFirstDayOfRange(date);
          const lastDayOfRange = isLastDayOfRange(date);
          const disabled = !dateEnabled(date);
          const event = dailyEvents[key];

          return (
            <div
              key={key}
              data-tip={event?.id ? event?.name : defaultEventTooltipText}
              data-effect="solid"
              style={{ width: "14.28%" }}
              className={cn(
                "px-1 mb-1 py-1 relative transition ease-in-out duration-100",
                {
                  "bg-primary bg-opacity-25": isInRange && rangeEnabled,
                  "rounded-l-full": isInRange && firstDayOfRange,
                  "rounded-r-full": isInRange && lastDayOfRange,
                }
              )}
            >
              <div
                onClick={() => !disabled && onHandleSelect(date, key)}
                onMouseEnter={() => setHovered(date)}
                onMouseLeave={() => setHovered()}
                className={cn(
                  "flex-center select-none text-center text-sm leading-loose transition ease-in-out duration-100",
                  {
                    "opacity-50 cursor-not-allowed": disabled,
                    "cursor-pointer": !disabled,
                    "bg-primary text-black rounded-full": isActive,
                    "hover:bg-primary hover:bg-opacity-50 rounded-full":
                      !isActive,
                    "text-neutral-700 hover:text-foreground":
                      !isActive && theme === THEME.light,
                    "text-muted-foreground hover:text-foreground":
                      !isActive && theme === THEME.dark,
                  }
                )}
              >
                <span>{dateIndex + 1}</span>
                {!!event ? (
                  <div
                    className="absolute inset-x-0 w-1 h-1 mx-auto transform translate-x-2 -translate-y-2 rounded-full bg-primary"
                    style={{ transform: "" }}
                  />
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
