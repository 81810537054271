import { useRouter } from "next/router";
import { useCurrentUser } from "queries/current-user";
import React, { useMemo } from "react";
import useRequest from "./useRequest";

export const useBootcamps = (props = {}) => {
  const {
    query: { bootcampId },
  } = useRouter();
  const { currentUser, isCoach } = useCurrentUser();
  const { data, revalidate } = useRequest("bootcamps", {
    ...props,
    refetchOnWindowFocus: false,
  });

  const bootcamps = useMemo(() => {
    if (!data) return [];
    if (data?.length === 1) return data;
    if (!isCoach) return data;
    if (!currentUser?.id) return data;

    const coachTeam = data.find(({ id }) => currentUser.bootcampId === id);
    const filtered = data.filter(({ id }) => currentUser.bootcampId !== id);
    return [coachTeam, ...filtered];
  }, [data, currentUser, isCoach]);

  const [currentBootcamp] = React.useMemo(
    () =>
      bootcampId ? bootcamps.filter(({ id }) => bootcampId === id) : bootcamps,
    [bootcampId, bootcamps]
  );

  const getBootcampById = React.useCallback(
    (teamID) => {
      if (teamID) {
        return bootcamps.filter(({ id }) => teamID === id)?.[0];
      }
    },
    [bootcamps]
  );

  return {
    revalidate,
    bootcamps,
    bootcampId,
    currentBootcamp,
    getBootcampById,
  };
};
