import { differenceInSeconds } from "date-fns";
import React, { useEffect, useState } from "react";
import { fancyTimeFormat } from "ui/lib/helpers";

export const Timer = React.memo(() => {
  const [start, setStart] = useState(new Date());
  const [count, setCount] = useState(0);

  useEffect(() => {
    let interval = setInterval(() => {
      setCount((count) => count + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <span key={count} className="">
      {fancyTimeFormat(differenceInSeconds(new Date(), start))}
    </span>
  );
});
