import Cookies from "js-cookie";
import { useEffect } from "react";

export const useClearAWSCreds = () => {
  useEffect(() => {
    const accessKeyId = Cookies.get("s3_accessKeyId");
    const secretAccessKey = Cookies.get("s3_secretAccessKey");

    if (accessKeyId) {
      Cookies.remove("s3_accessKeyId");
    }

    if (secretAccessKey) {
      Cookies.remove("s3_secretAccessKey");
    }
  }, []);
};
