import * as Sentry from "@sentry/nextjs";
import { updateMember } from "apollo/components/member/requests";
import { useMultiSelect } from "apollo/components/multi-select/container";
import { useCommandCenter } from "apollo/containers/command-center";
import { useCurrentUser } from "queries/current-user";
import React, { useEffect, useMemo } from "react";
import { mutate } from "swr";
import { useErrorNotification } from "ui/containers/error-notification";
import useRequest from "ui/lib/useRequest";
import { createContainer } from "unstated-next";

const items = [
  { value: "skip-welcome-workout", label: "Skip Welcome Workout" },
];

const useContainer = () => {
  const { isAdmin } = useCurrentUser();
  const { setItemsLength, context, getFromContext } = useCommandCenter();
  const [hasChanges, setHasChanges] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [selectedItems, setSelectedItems] = React.useState<Array<any>>([]);
  const { activateError } = useErrorNotification();

  const memberId = useMemo(() => getFromContext("memberId"), [context]);
  const {
    data: user,
    isValidating,
    revalidate,
  } = useRequest(
    memberId && `users/${memberId}${isAdmin ? "/sensitive" : ""}`,
    {
      revalidateOnFocus: true,
      revalidateOnMount: true,
    }
  );

  useEffect(() => {
    if (user?.featureFlags) {
      const userItems = user?.featureFlags?.map?.(
        (n) => items.find(({ value }) => value === n) || { value: n, label: n }
      );
      setSelectedItems(userItems || []);
    } else {
      setSelectedItems([]);
    }
  }, [user, isValidating]);

  const addSelectedItem = async (item) => {
    try {
      setIsSaving(true);
      const current = user?.featureFlags || [];
      await updateMember(
        memberId,
        {
          featureFlags: [...current, item.value],
        },
        mutate
      );

      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
      activateError("Unable to save");
      console.error(error);
      Sentry.captureException(error);
    }
  };

  const removeSelectedItem = async (item) => {
    try {
      setIsSaving(true);
      const current = user?.featureFlags || [];
      const featureFlags = current.filter((n) => n !== item.value);
      await updateMember(
        memberId,
        {
          featureFlags,
        },
        mutate
      );
      setIsSaving(false);
      setSelectedItems(featureFlags);
      revalidate();
    } catch (error) {
      setIsSaving(false);
      activateError("Unable to save");
      console.error(error);
      Sentry.captureException(error);
    }
  };

  const multiSelectProps = useMultiSelect({
    items,
    selectedItems,
    removeSelectedItem,
    onSelectedItemsChange: ({ selectedItems }) => {
      setSelectedItems((current) => {
        const [intersection] = selectedItems.filter(
          (n) => !current.map((s) => s.value).includes(n.value)
        );
        addSelectedItem(intersection);
        return selectedItems;
      });
    },
  });

  useEffect(() => {
    setItemsLength(multiSelectProps?.inputItems?.length || 0);
  }, [multiSelectProps?.inputItems]);

  return {
    ...multiSelectProps,
    removeSelectedItem,
    loading: isSaving || isValidating,
    memberId,
    selectedItems,
    hasChanges,
  };
};

export const {
  useContainer: useCommandCenterMembersFeatureFlags,
  Provider: CommandCenterMembersFeatureFlagsProvider,
} = createContainer(useContainer);
