import type { SVGProps } from "react";

export function ConnectIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill="currentColor"
          d="M10 0C4.477 0 0 4.283 0 9.568c0 2.539 1.035 4.845 2.72 6.557.37.376.619.891.52 1.422a5.3 5.3 0 0 1-1.088 2.349 6.5 6.5 0 0 0 4.224-.656c.453-.243.68-.363.843-.387.16-.024.389.019.848.104.637.12 1.285.179 1.933.179 5.523 0 10-4.283 10-9.568S15.523 0 10 0M6.01 11.493h-.007a1.494 1.494 0 0 1 0-2.986h.008a1.494 1.494 0 0 1 0 2.986m3.995 0h-.008a1.494 1.494 0 0 1 0-2.986h.008a1.494 1.494 0 0 1 0 2.986m3.995 0h-.008a1.494 1.494 0 0 1 0-2.986H14a1.494 1.494 0 0 1 0 2.986"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
