import {
  differenceInYears,
  endOfDay,
  isAfter,
  isBefore,
  isWithinInterval,
  startOfDay,
  subDays,
} from "date-fns";
import addDays from "date-fns/addDays";
import * as R from "ramda";
import { CHAT_FLAGS } from "ui/lib/constants";
import { getValidDate } from "ui/lib/helpers";
import { IMember, IMemberFromServer, membershipStatus } from "../types/types";
import { getTags } from "./getTags";

const isBeforeToday = (value: Date): boolean =>
  isBefore(getValidDate(value), startOfDay(new Date()));

export const getMembershipStatus = ({
  membershipStatus,
  trialExpirationTS,
}: {
  membershipStatus: membershipStatus;
  trialExpirationTS: Date;
}): membershipStatus => {
  if (!["trialing", "trialing expired"].includes(membershipStatus))
    return membershipStatus;
  if (!isBeforeToday(trialExpirationTS)) return "trialing";
  return `trialing expired`;
};

export const checkChatAvailability = ({
  lastActivityTS,
  membershipStatus,
  trialExpirationTS,
  teamID,
  id,
}) => {
  const _membershipStatus = getMembershipStatus({
    membershipStatus,
    trialExpirationTS,
  })?.toLowerCase?.();

  if (["free", "paid", "trialing", "canceling"].includes(_membershipStatus))
    return true;

  if (!teamID) return false;

  return isWithinInterval(getValidDate(lastActivityTS), {
    end: addDays(new Date(), 1),
    start: subDays(new Date(), 8),
  });
};

export const getUserAge = (dob: Date) =>
  differenceInYears(new Date(), getValidDate(dob));

const teamNameLense = R.lensPath(["recentTeamHistory", 0, "teamName"]);

const getBoolEnabled = R.compose(
  R.when(R.is(Boolean), (value: boolean) => (value ? "Yes" : "No")),
  R.defaultTo("Unknown")
);

const getTeamJoins = R.compose(R.when(R.is(Boolean), R.dec), R.defaultTo(0));

const getFlagTypeLabels = R.compose(
  R.map(R.compose(R.prop("label"), R.prop(R.__, CHAT_FLAGS))),
  R.defaultTo([])
);

const dateWithin_Days =
  (days: number) =>
  (value: Date | string): boolean => {
    if (!value) return false;

    return isWithinInterval(getValidDate(value), {
      end: endOfDay(new Date()),
      start: startOfDay(subDays(new Date(), days)),
    });
  };

const getLastMessageRecieved = (user: IMemberFromServer) => {
  if (!user.coachRoomLastImpersonatedCoachMessageRecievedTS)
    return user.coachRoomLastCoachMessageRecievedTS;
  if (!user.coachRoomLastCoachMessageRecievedTS)
    return user.coachRoomLastImpersonatedCoachMessageRecievedTS;

  if (
    isAfter(
      getValidDate(user.coachRoomLastImpersonatedCoachMessageRecievedTS),
      getValidDate(user.coachRoomLastCoachMessageRecievedTS)
    )
  ) {
    return user.coachRoomLastImpersonatedCoachMessageRecievedTS;
  }
  return user.coachRoomLastCoachMessageRecievedTS;
};

const getTopRecommendedTeamIds = (user: IMemberFromServer) => {
  if (!user?.recommendedTeamIDs?.length) return [];

  if (Array.isArray(user.recommendedTeamIDs)) {
    return user.recommendedTeamIDs;
  }

  if (typeof user.recommendedTeamIDs === "string")
    return [user.recommendedTeamIDs];

  return [];
};

const getTopRecommendedTeamId = (user: IMemberFromServer) => {
  const ids = getTopRecommendedTeamIds(user);
  const [id] = ids;
  return id;
};

const getTopRecommendedTeamName = (
  user: IMemberFromServer,
  bootcamps: any[]
) => {
  if (!bootcamps?.length) return "";
  const [id] = getTopRecommendedTeamIds(user);
  return bootcamps?.find?.((b) => b?.id === id)?.name;
};

export const transformUser =
  (isAdmin: boolean, bootcamps: any[]) => (user: IMemberFromServer) => {
    const { membershipStatus, trialExpirationTS } = user;

    return R.merge(user, {
      avgWorkoutRating: Math.round(user.avgWorkoutRating || 0),
      fullName: `${user.givenName} ${user.familyName}`.trimLeft(),
      chatAvailable: checkChatAvailability(user),
      teamName: R.view(teamNameLense, user),
      bootcampId: user.teamID,
      membershipStatus: getMembershipStatus({
        membershipStatus,
        trialExpirationTS,
      }),
      age: R.when(Boolean, getUserAge, user.dob),
      isPushEnabled: getBoolEnabled(user.isPushEnabled),
      // activeRoomFlagTypes: getFlagTypeLabels(user.activeRoomFlagTypes),
      tags: getTags(user.tags),
      numTeamJoins: getTeamJoins(user.numTeamJoins),
      coachRoomLastCoachMessageRecievedTS: getLastMessageRecieved(user),
      topRecommendedTeamID: getTopRecommendedTeamId(user),
      topRecommendedTeamName: getTopRecommendedTeamName(user, bootcamps),
      profileImg: user.profileImgImgixURL,
    });
  };

export const transformUsersForMemberTable = (
  isAdmin: boolean,
  bootcamps: any[]
): IMember[] =>
  R.compose(
    R.map(transformUser(isAdmin, bootcamps)),
    R.defaultTo([]),
    R.path(["data", "users"])
  );
