import { FlagIcon, TagIcon } from "@heroicons/react/solid";
import {
  CalendarIcon,
  ClipboardCopyIcon,
  FaceIcon,
  LinkBreak2Icon,
  LockClosedIcon,
} from "@radix-ui/react-icons";
import { useCommandCenter } from "apollo/containers/command-center";
import { useRouter } from "next/router";
import React from "react";
import toast from "react-hot-toast";
import useClipboard from "react-use-clipboard";
import { buildShortName } from "ui/components/chat/name-helper";
import { ConnectIcon } from "ui/components/icons/ConnectIcon";
import { Spinner } from "ui/components/icons/Spinner";
import { TeamsIcon } from "ui/components/icons/TeamsIcon";
import { Suspended } from "ui/components/suspended";
import { keyCodes } from "ui/lib/constants";
import useRequest from "ui/lib/useRequest";
import { Header } from "..";
import { ACTIONS } from "../constants";
import {
  CommandCenterMembersProvider,
  useCommandCenterMembers,
} from "../containers/members";
import { useDefaultCommandCenterControls } from "../hooks";
import { LineItem } from "../line-item";
import { MemberDateCommandCenterContext } from "./date";
import { MemberFeatureFlagsCommandCenterContext } from "./feature-flags";
import { CommandCenterMembersProgramContext } from "./program";
import { CommandCenterMembersSearch } from "./search";
import { CommandCenterMembersStatusContext } from "./status";
import { MemberTagsCommandCenterContext } from "./tags";

export const DisplayMember = ({ memberId }) => {
  const { pushToContext } = useCommandCenter();
  const { data, isValidating } = useRequest(
    memberId && `users/detailed?userId=${memberId}`
  );

  const [user] = data?.users || [];

  if (isValidating && !user) {
    return <Spinner className="w-[15px] h-[15px] text-white" />;
  }

  const handleClick = () => {
    pushToContext([
      { key: "memberId", value: user.id },
      { key: "ACTION", value: "" },
    ]);
  };

  return (
    <button onClick={handleClick} className="capitalize hover:text-neutral-50">
      {buildShortName({
        givenName: user?.givenName,
        familyName: user?.familyName,
      }).toLowerCase()}
    </button>
  );
};

export const MembersHeader = () => {
  return (
    <Header id="memberId">
      {(props) => <DisplayMember key={props.key} memberId={props.value} />}
    </Header>
  );
};

const commandOptions = [
  {
    value: ACTIONS.MEMBER.COPY_ID,
    label: ACTIONS.MEMBER.COPY_ID,
    order: 0,
    icon: ClipboardCopyIcon,
  },
  {
    value: ACTIONS.MEMBER.UPDATE_PROGRAM,
    label: ACTIONS.MEMBER.UPDATE_PROGRAM,
    order: 1,
    icon: TeamsIcon,
  },
  {
    value: ACTIONS.MEMBER.EDIT_TRIAL_EXPIRATION_DATE,
    label: "Edit Trial Expiration Date",
    disabled: (member) => {
      return !["trialing", "trialing expired"].includes(
        member?.membershipStatus
      );
    },
    order: 2,
    icon: CalendarIcon,
  },
  {
    value: ACTIONS.MEMBER.EDIT_TAGS,
    label: "Edit Tags",
    order: 3,
    icon: TagIcon,
  },
  {
    value: ACTIONS.MEMBER.UPDATE_STATUS_FREE,
    label: ACTIONS.MEMBER.UPDATE_STATUS_FREE,
    disabled: (member) => ["paid", "free"].includes(member?.membershipStatus),
    order: 4,
    icon: LinkBreak2Icon,
  },
  {
    value: ACTIONS.MEMBER.UPDATE_FEATURE_FLAGS,
    label: ACTIONS.MEMBER.UPDATE_FEATURE_FLAGS,
    order: 5,
    icon: FlagIcon,
  },
  {
    value: ACTIONS.MEMBER.AMPLITUDE,
    label: ACTIONS.MEMBER.AMPLITUDE,
    order: 6,
    icon: (props) => (
      <img
        alt="amplitude"
        src="/assets/images/icons/amplitude.svg"
        {...props}
      />
    ),
  },
  {
    value: ACTIONS.MEMBER.OPEN_PROFILE,
    label: ACTIONS.MEMBER.OPEN_PROFILE,
    order: 7,
    icon: FaceIcon,
  },
  {
    value: ACTIONS.MEMBER.OPEN_CHAT,
    label: ACTIONS.MEMBER.OPEN_CHAT,
    disabled: (member) => !member?.bootcamp?.id || !member?.roomId,
    order: 8,
    icon: ConnectIcon,
  },
  {
    value: ACTIONS.MEMBER.OPEN_ENTITLEMENTS,
    label: ACTIONS.MEMBER.OPEN_ENTITLEMENTS,
    order: 9,
    icon: LockClosedIcon,
  },
  {
    value: ACTIONS.MEMBER.OPEN_ENTITLEMENT_CHANGE_TEAM,
    label: ACTIONS.MEMBER.OPEN_ENTITLEMENT_CHANGE_TEAM,
    order: 10,
    icon: TeamsIcon,
  },
];

// const scrambleUser = async (memberID) => {

// }

const MembersCommandCenterContent = () => {
  const { pushToContext, setActive } = useCommandCenter();
  const { member } = useCommandCenterMembers();
  const router = useRouter();
  const [_, setMemberIDCopied] = useClipboard(member?.id, {
    successDuration: 3000,
  });

  const {
    inputValue,
    getInputProps,
    filteredData,
    highlightedIndex,
    setHighlightedIndex,
    rowVirtualizer,
    parentRef,
  } = useDefaultCommandCenterControls({
    data: commandOptions,
    matchKeys: ["label"],
    sorter: (items) => items.sort((a, b) => a.order - b.order),
  });
  const { totalSize, virtualItems } = rowVirtualizer;

  const handleClick = async (option) => {
    switch (option.value) {
      // case ACTIONS.MEMBER.SCRAMBLE_USER: {
      //   await scrambleUser(member.id)
      //   toast.success("Member scrambled!", {
      //     position: "top-center",
      //   });
      //   return;
      // }
      case ACTIONS.MEMBER.COPY_ID: {
        setMemberIDCopied();
        setActive(false);
        toast.success("Member ID Copied!", {
          position: "top-center",
        });
        return;
      }
      case ACTIONS.MEMBER.OPEN_CHAT:
        if (!member?.bootcamp?.id || !member?.roomId) return;
        setActive(false);
        return router.push(
          `/programs/${member.bootcamp.id}/chat?roomId=${member.roomId}`
        );
      case ACTIONS.MEMBER.OPEN_PROFILE:
        setActive(false);
        if (router.pathname !== "/members/[memberId]") {
          return window.open(`/members/${member.id}`, "_blank");
        }
        return router.push(`/members/${member.id}`);
      case ACTIONS.MEMBER.OPEN_ENTITLEMENTS:
        setActive(false);
        if (router.pathname !== "/members/[memberId]") {
          return window.open(`/members/${member.id}?p=entitlements`, "_blank");
        }
        return router.push(`/members/${member.id}?p=entitlements`);
      case ACTIONS.MEMBER.OPEN_ENTITLEMENT_CHANGE_TEAM:
        setActive(false);
        if (router.pathname !== "/members/[memberId]") {
          return window.open(
            `/members/${member.id}?p=entitlements&selectedEntitlement=com.ladder.teams.modify`,
            "_blank"
          );
        }
        return router.push(
          `/members/${member.id}?p=entitlements&selectedEntitlement=com.ladder.teams.modify`
        );
      case ACTIONS.MEMBER.AMPLITUDE:
        setActive(false);
        return window.open(
          `https://analytics.amplitude.com/ladderteams/project/348761/search/${member.id}`,
          "_blank"
        );
      default:
        pushToContext({ key: "ACTION", value: option.value });
    }
  };

  // Enter
  const handleKeyDown = (e) => {
    if (
      e.keyCode === keyCodes.enter &&
      !filteredData[highlightedIndex]?.disabled?.(member)
    ) {
      e.preventDefault();
      handleClick(filteredData[highlightedIndex]);
    }
  };

  return (
    <>
      <div id="command-center-header" className="shadow">
        <MembersHeader />
        <div className="border-b">
          <input
            {...getInputProps()}
            onKeyDown={handleKeyDown}
            placeholder="Do something..."
          />
        </div>
      </div>
      <div
        // @ts-ignore
        ref={parentRef}
        style={{ overflow: "hidden auto" }}
        className="relative w-full"
      >
        <ul className="w-full shadow-md" style={{ height: totalSize }}>
          {virtualItems.map(({ size, start, index }) => {
            const option = filteredData[index];
            return (
              <LineItem
                key={`${option.value}${index}`}
                textToHighlight={option.label}
                data={member}
                option={option}
                size={size}
                start={start}
                searchWords={inputValue}
                highlighted={highlightedIndex === index}
                onMouseEnter={() => setHighlightedIndex(index)}
                handleClick={() => handleClick(option)}
                renderIcon={() => {
                  const Icon = filteredData[index].icon;
                  if (!Icon) return null;
                  return <Icon className="w-4 h-4" />;
                }}
              />
            );
          })}
        </ul>
      </div>
    </>
  );
};

const MembersCommandCenterSwitch = React.memo(() => {
  const { context } = useCommandCenter();

  const ACTION = context.find(({ key }) => key === "ACTION");
  const memberId = context.find(({ key }) => key === "memberId");

  if (!memberId?.value) return <CommandCenterMembersSearch />;
  if (!ACTION?.value) return <MembersCommandCenterContent />;

  switch (ACTION.value) {
    case ACTIONS.MEMBER.EDIT_TAGS:
      return <MemberTagsCommandCenterContext />;
    case ACTIONS.MEMBER.EDIT_TRIAL_EXPIRATION_DATE:
      return <MemberDateCommandCenterContext />;
    case ACTIONS.MEMBER.UPDATE_PROGRAM:
      return <CommandCenterMembersProgramContext />;
    case ACTIONS.MEMBER.UPDATE_FEATURE_FLAGS:
      return <MemberFeatureFlagsCommandCenterContext />;
    case ACTIONS.MEMBER.UPDATE_STATUS_FREE:
      return <CommandCenterMembersStatusContext />;
    default:
      return null;
  }
});

MembersCommandCenterSwitch.displayName = "MembersCommandCenterSwitch";

const LoadingFallback = () => {
  return (
    <div className="flex-col w-full h-full space-y-4 flex-center">
      <Spinner className="w-[20px] h-[20px] text-white" />
      <div>loading...</div>
    </div>
  );
};

export const MembersCommandCenterContext = React.memo(() => {
  return (
    <Suspended fallback={<LoadingFallback />}>
      <CommandCenterMembersProvider>
        <MembersCommandCenterSwitch />
      </CommandCenterMembersProvider>
    </Suspended>
  );
});

MembersCommandCenterContext.displayName = "MembersCommandCenterContext";
