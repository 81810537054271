import { useKeyDown } from "./useKeyDown";

export const useKeyBoardNavigation = (
  total,
  activeIndex,
  handleSetNextIndex
) => {
  const handlePrevious = (e) => {
    if (e?.defaultPrevented) return;
    if (!activeIndex) return handleSetNextIndex(total - 1);
    handleSetNextIndex(activeIndex - 1);
  };

  const handleNext = (e) => {
    if (e?.defaultPrevented) return;
    if (activeIndex === total - 1) return handleSetNextIndex(0);
    handleSetNextIndex(activeIndex + 1);
  };

  useKeyDown(38, handlePrevious, [total, handlePrevious, activeIndex]); //up
  useKeyDown(40, handleNext, [handleNext, activeIndex, total]); //down
};
