import { Dialog, DialogContent } from "ui/components/primitives/dialog";
import { Drawer, DrawerContent } from "ui/components/primitives/drawer";
import { useConfirmModal } from "ui/containers/confirm-modal";
import { useIsDesktop } from "ui/hooks/use-media-query";
import { cn } from "ui/lib/utils";
import { Spinner } from "../icons/Spinner";

export const ConfirmModal = () => {
  const isDesktop = useIsDesktop();
  const {
    confirmModalActive,
    title,
    description,
    cancelLabel,
    confirmLabel,
    handleCancel,
    handleConfirm,
    loading,
  } = useConfirmModal();

  const Content = () => (
    <div className="flex-col flex-grow text-center flex-center">
      <div className="mb-8">
        <h4 className="text-xl font-bold text-neutral-50">{title}</h4>
        <p className="text-neutral-300 text-md">{description}</p>
      </div>
      <div className="flex space-x-6">
        <button
          autoFocus
          tabIndex={0}
          onClick={handleConfirm}
          className={cn(
            "relative flex-center text-black px-8 py-2 border border-transparent rounded-full shadow-button bg-primary font-medium hover:opacity-75"
          )}
        >
          {loading ? (
            <div className="absolute">
              <Spinner className="w-[25px] h-[25px] text-black" />
            </div>
          ) : (
            confirmLabel
          )}
        </button>
        {cancelLabel ? (
          <button
            onClick={handleCancel}
            className="px-8 py-2 font-medium border text-neutral-300 rounded-full border hover:opacity-75"
          >
            {cancelLabel}
          </button>
        ) : null}
      </div>
    </div>
  );

  if (!confirmModalActive) return null;

  return isDesktop ? (
    <Dialog
      open={confirmModalActive}
      onOpenChange={(value) => !value && handleCancel()}
    >
      <DialogContent overlay close={false} className="border">
        <Content />
      </DialogContent>
    </Dialog>
  ) : (
    <Drawer
      open={confirmModalActive}
      onOpenChange={(value) => !value && handleCancel()}
    >
      <DrawerContent className="border pb-6">
        <Content />
      </DrawerContent>
    </Drawer>
  );
};
