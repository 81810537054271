import { useCommandCenter } from "apollo/containers/command-center";
import { matchSorter } from "match-sorter";
import React, { useEffect } from "react";
import { useVirtual } from "react-virtual";
import { useDebounce } from "ui/lib/hooks";
import { useKeyBoardNavigation } from "ui/lib/use-key-navigation";

export const useDefaultCommandCenterControls = ({
  data,
  searchValue = "",
  setSearchValue = (n) => null,
  matchKeys = [],
  sorter = (rankedItems) => rankedItems,
}: {
  data: any;
  searchValue?: string;
  setSearchValue?: (n: string) => void;
  matchKeys?: string[];
  sorter?: (rankedItems: any[]) => any[];
}) => {
  const { setItemsLength } = useCommandCenter();

  const parentRef = React.useRef<HTMLDivElement>();
  const [inputValue, setInputValue] = React.useState(searchValue);
  const debouncedInputValue = useDebounce(inputValue, 200);
  const [filteredData, setFilteredData] = React.useState(() => data || []);
  const [highlightedIndex, setHighlightedIndex] = React.useState(0);

  useEffect(() => {
    setInputValue(searchValue);
  }, [searchValue]);

  useEffect(() => {
    setSearchValue(debouncedInputValue);
  }, [debouncedInputValue]);

  const getInputProps = React.useCallback(
    (props = {}) => ({
      autoFocus: true,
      value: inputValue,
      onChange: ({ target }) => {
        setInputValue(target.value);
      },
      className: "w-full p-4 text-xl bg-transparent focus:ring-0",
      ...props,
    }),
    [inputValue]
  );

  const rowVirtualizer = useVirtual({
    parentRef,
    size: filteredData.length,
    estimateSize: React.useCallback(() => 46, []),
    overscan: 10,
  });

  useEffect(() => {
    rowVirtualizer.scrollToIndex(highlightedIndex);
  }, [highlightedIndex]);

  useEffect(() => {
    setFilteredData(
      matchSorter(data, inputValue, {
        keys: matchKeys,
        sorter,
      })
    );
  }, [data, inputValue]);

  useEffect(() => {
    setItemsLength(filteredData?.length);
  }, [filteredData?.length]);

  useEffect(() => {
    if (inputValue) {
      setHighlightedIndex(0);
    }
  }, [inputValue]);

  useKeyBoardNavigation(
    filteredData?.length,
    highlightedIndex,
    setHighlightedIndex
  );

  return {
    parentRef,
    inputValue,
    setInputValue,
    filteredData,
    setFilteredData,
    highlightedIndex,
    setHighlightedIndex,
    rowVirtualizer,
    getInputProps,
  };
};
