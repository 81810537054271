import { Analytics } from "@vercel/analytics/react";
import { CancelableToastProvider } from "apollo/components/cancelable-toast/container";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "react-query";
import { SWRConfig } from "swr";
import { ConfirmModalProvider } from "ui/containers/confirm-modal";
import { FileUploaderProvider } from "ui/containers/file-uploader";
import fetcher from "ui/lib/fetch";
import { CalendarEventProvider } from "./calendar-event";
import { CommandCenterProvider } from "./command-center";
import { ModalProvider } from "./modal";
import { VersionProvider } from "./version";
const queryClient = new QueryClient();

const Root = ({ children }) => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <SWRConfig value={{ fetcher }}>
          <VersionProvider>
            <CancelableToastProvider>
              <ModalProvider>
                <FileUploaderProvider>
                  <ConfirmModalProvider>
                    <CommandCenterProvider>
                      <CalendarEventProvider>{children}</CalendarEventProvider>
                    </CommandCenterProvider>
                  </ConfirmModalProvider>
                </FileUploaderProvider>
              </ModalProvider>
            </CancelableToastProvider>
          </VersionProvider>
        </SWRConfig>
      </QueryClientProvider>
      <Toaster
        position="top-center"
        toastOptions={{
          style: {
            borderRadius: "500px",
            background: "#333",
            color: "#fff",
          },
        }}
      />
      <Analytics />
    </>
  );
};

export default Root;
