import * as React from "react";

function Photograph(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.978 1.6H8.94c-1.087 0-1.957 0-2.661.057-.723.06-1.348.184-1.924.477a4.9 4.9 0 00-2.142 2.141c-.293.576-.417 1.202-.476 1.924-.058.704-.058 1.575-.058 2.662v6.278c0 1.087 0 1.958.058 2.662.059.722.183 1.348.476 1.924a4.9 4.9 0 002.142 2.14c.576.294 1.201.418 1.924.478.704.057 1.574.057 2.661.057H15.812c1.087 0 1.958 0 2.662-.057.722-.06 1.348-.184 1.924-.477a4.9 4.9 0 002.14-2.142c.294-.575.418-1.2.477-1.924.058-.703.058-1.574.058-2.661V8.86c0-1.087 0-1.958-.058-2.662-.059-.722-.183-1.348-.476-1.924a4.9 4.9 0 00-2.141-2.141c-.576-.293-1.202-.417-1.924-.477-.704-.057-1.575-.057-2.662-.057H8.978zM5.171 3.738c.28-.143.642-.237 1.253-.287.622-.05 1.42-.051 2.554-.051h6.795c1.135 0 1.932 0 2.554.051.611.05.974.144 1.253.287a3.1 3.1 0 011.355 1.355c.143.28.237.642.287 1.253.05.622.051 1.419.051 2.554v5.564l-2.144-2.845a2 2 0 00-3.194 0l-2.87 3.809a2 2 0 01-3.101.114l-.725-.826a2 2 0 00-3.008 0l-2.69 3.068a8.188 8.188 0 01-.011-.13c-.051-.622-.052-1.42-.052-2.554V8.9c0-1.135 0-1.932.052-2.554.05-.611.144-.974.286-1.253a3.1 3.1 0 011.355-1.355zM10.829 8c0 1.38-1.155 2.5-2.579 2.5-1.424 0-2.578-1.12-2.578-2.5S6.826 5.5 8.25 5.5 10.83 6.62 10.83 8z"
        fill="#B7BCD9"
      />
    </svg>
  );
}

export default Photograph;
