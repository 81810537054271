import { getDetailedUsersDistinctData } from "ui/lib/getDetailedUsersDistinctData";

export const getMemberRemoteOptions =
  (key, labelDict = {}) =>
  async () => {
    const result = await getDetailedUsersDistinctData({ [key]: true });
    return result.detailedUsersDistinctData[key].map((n) => ({
      label: labelDict?.[n]?.label || n,
      value: n,
    }));
  };
