import * as Sentry from "@sentry/nextjs";
import { useState } from "react";
import { useErrorNotification } from "ui/containers/error-notification";
import { createContainer } from "unstated-next";

const defaultTitle = "Are you sure you want to delete?";

const useContainer = () => {
  const [confirmModalActive, setConfirmModalActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(defaultTitle);
  const [id, setId] = useState("");
  const [description, setDescription] = useState("");
  const [cancelLabel, setCancelLabel] = useState("No");
  const [confirmLabel, setConfirmLabel] = useState("Yes");
  const { activateError } = useErrorNotification();
  const [actions, setActions] = useState({
    onConfirm: async () => console.debug("confirm action not set"),
    onCancel: async () => console.debug("cancel action not set"),
  });

  const handleCancel = () => {
    setTitle(defaultTitle);
    setDescription("");
    setCancelLabel("No");
    setConfirmLabel("Yes");
    setConfirmModalActive(false);
    actions?.onCancel?.();
    setLoading(false);
    setId("");
  };

  const handleConfirm = async () => {
    setLoading(true);
    try {
      await actions.onConfirm();
    } catch (error) {
      activateError("Unable to save");
      Sentry.captureException(error);
    }
    setLoading(false);
    setConfirmModalActive(false);
    setTitle(defaultTitle);
    setId("");
  };

  const setOnConfirm = (func, id) => {
    setActions((c) => ({ ...c, onConfirm: func }));
    setId(id);
  };
  const setOnCancel = (func) => {
    setActions((c) => ({ ...c, onCancel: func }));
  };

  return {
    id,
    loading,
    setLoading,
    confirmModalActive,
    setConfirmModalActive,
    title,
    setTitle,
    description,
    setDescription,
    cancelLabel,
    setCancelLabel,
    confirmLabel,
    setConfirmLabel,
    handleCancel,
    handleConfirm,
    setOnConfirm,
    setOnCancel,
  };
};

export const { useContainer: useConfirmModal, Provider: ConfirmModalProvider } =
  createContainer(useContainer);

// simple interface for useConfirmModal
export const useConfirmRequest = (handle) => {
  const { setConfirmModalActive, setOnConfirm } = useConfirmModal();
  const handleRequest = async () => {
    setOnConfirm(handle);
    setConfirmModalActive(true);
  };
  return handleRequest;
};
