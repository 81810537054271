"use client";

import { useVersion } from "apollo/containers/version";
import React from "react";
import Info from "ui/components/icons/Info";
import {
  Portal,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "ui/components/primitives/tooltip";

export const VersionTracker = React.memo(({ children }) => {
  const { changelog, version, latestVersion } = useVersion();
  const shouldRefresh = !!latestVersion && version !== latestVersion;
  // const shouldRefresh = true;

  return (
    <div className="hidden text-foreground lg:block">
      {shouldRefresh ? (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <button
                onClick={() => window.location.reload()}
                className="px-2 py-1 rounded-full bg-royal border border-blue-400 shadow-button"
              >
                <div className="flex space-x-1">
                  <div className="flex-shrink-0 flex-center">
                    <Info className="w-5 h-5 text-blue-50" />
                  </div>
                  <div className="flex flex-col flex-center text-center">
                    <p className="text-xs text-blue-50">Update</p>
                  </div>
                </div>
              </button>
            </TooltipTrigger>
            {changelog ? (
              <Portal>
                <TooltipContent className="max-w-md text-center">
                  {changelog}
                </TooltipContent>
              </Portal>
            ) : null}
          </Tooltip>
        </TooltipProvider>
      ) : (
        <div className="border p-1 rounded-full bg-background flex-center text-neutral-50">
          <TooltipProvider>
            <Tooltip>
              <div className="px-2 py-2 gap-2 flex-center">
                <TooltipTrigger>
                  <Info className="w-4 h-4" />
                </TooltipTrigger>
                <span className="block text-xs">V{version}</span>
              </div>
              {changelog ? (
                <Portal>
                  <TooltipContent className="max-w-md text-center">
                    {changelog}
                  </TooltipContent>
                </Portal>
              ) : null}
            </Tooltip>
          </TooltipProvider>
          {children}
        </div>
      )}
    </div>
  );
});
