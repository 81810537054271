import { CaretSortIcon } from "@radix-ui/react-icons";
import { useCombobox, useMultipleSelection } from "downshift";
import { useEffect, useState } from "react";
import useDimensions from "react-use-dimensions";
import { createDictionary } from "ui/lib/helpers";
import { cn } from "ui/lib/utils";

const CellLabel = ({ children, limitedValues, handleRemoveItem }) => {
  const [ref, { width }] = useDimensions();
  const maxWidth = 200 / limitedValues.length;
  return (
    <div className="flex">
      <div
        style={{ backgroundColor: "#276DF1" }}
        className={cn(
          "relative group shadow-button rounded-full cursor-default text-sm bg-transparent text-foreground justify-start overflow-hidden truncate flex-center",
          {
            "px-2": width <= maxWidth - 3,
            "pl-2 pr-0": width >= maxWidth - 3,
          }
        )}
      >
        <div
          key={children}
          ref={ref}
          style={{ maxWidth }}
          className="relative truncate"
        >
          {children}
        </div>
        <button
          type="button"
          onClick={handleRemoveItem}
          className="absolute shadow-button transform transition duration-150 ease-in-out scale-0 opacity-0 group-hover:opacity-100 group-hover:scale-100 right-1 bg-royal-900 flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-foreground hover:bg-royal-800 no-highlight focus:outline-none focus:bg-indigo-500 focus:text-foreground"
        >
          <svg
            className="w-2 h-2"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 8 8"
          >
            <path
              strokeLinecap="round"
              strokeWidth="1.5"
              d="M1 1l6 6m0-6L1 7"
            />
          </svg>
        </button>
      </div>
      {width <= maxWidth - 5 ? <span>,</span> : null}
    </div>
  );
};

const CellLabels = ({
  limitedValues,
  selectedItems,
  optionValuesToLabel,
  handleRemoveItem,
  maxVisble,
}) => {
  if (!limitedValues.length) {
    return <span className="py-1 opacity-50">Not Selected</span>;
  }
  return (
    <div className="space-x-1 flex-center">
      {limitedValues.map((value, ndx) => (
        <CellLabel
          ndx={ndx}
          key={value}
          value={value}
          limitedValues={limitedValues}
          handleRemoveItem={() => handleRemoveItem({ value })}
        >
          {optionValuesToLabel[value]?.label || value}
        </CellLabel>
      ))}
      {selectedItems?.length > maxVisble ? (
        <span className="text-xs">
          (+{selectedItems.length - limitedValues.length})
        </span>
      ) : null}
    </div>
  );
};

export const DropdownMultipleCombobox = ({
  items = [],
  selectedItems = [],
  selectedItemValues = [],
  handleSelectedItemsChange,
  autoFocus,
  maxVisble = 3,
  keepOpenOnSelection = true,
} = {}) => {
  const optionValuesToLabel = createDictionary(items, "value");
  const [inputValue, setInputValue] = useState("");

  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
  } = useMultipleSelection({ selectedItems });

  // useEffect(() => {
  //   handleSelectedItemsChange(selectedItems);
  // }, [selectedItems]);

  const getFilteredItems = (items) =>
    items.filter(
      (item) =>
        (selectedItems.indexOf(item.value) < 0 &&
          item.value.toLowerCase().startsWith(inputValue.toLowerCase())) ||
        item.label.toLowerCase().startsWith(inputValue.toLowerCase())
    );

  const handleRemoveItem = (selectedItem) => {
    handleSelectedItemsChange(
      selectedItems
        .filter((n) => n.value !== selectedItem.value)
        .map((n) => n.value)
    );
  };

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectItem,
    setHighlightedIndex,
    ...props
  } = useCombobox({
    inputValue,
    items: getFilteredItems(items),
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: keepOpenOnSelection, // keep the menu open after selection.
          };
      }
      return changes;
    },
    onStateChange: ({ inputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue);
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem && selectedItemValues.includes(selectedItem.value)) {
            setInputValue("");
            handleRemoveItem(selectedItem);
            selectItem(null);
            break;
          }
          if (selectedItem) {
            setInputValue("");
            handleSelectedItemsChange(
              [...selectedItems, selectedItem].map((n) => n.value)
            );
            selectItem(null);
            break;
          }

          break;
        default:
          break;
      }
    },
  });

  const filteredItems = getFilteredItems(items);
  const inputProps = getInputProps(
    getDropdownProps({ preventKeyAction: isOpen })
  );

  useEffect(() => {
    inputProps?.ref?.current?.addEventListener?.("keyup", function (event) {
      if (event.key === "Enter") {
        console.log("enter");
      }
    });
  }, []);

  useEffect(() => {
    setHighlightedIndex(0);
  }, [inputValue]);

  const limitedValues = [...selectedItems]
    .slice(0, maxVisble)
    .map((n) => n.value);

  const [itemsRef, { width: itemsWidth }] = useDimensions();
  const [inputContainerRef, { width: inputContainerWidth }] = useDimensions();
  const maxWidth = inputContainerWidth - itemsWidth;

  return (
    <div className="w-full">
      <div className="relative">
        <div
          {...getToggleButtonProps()}
          className="flex h-9 w-full items-center justify-between rounded-md border border-input bg-transparent px-3 py-2 text-sm shadow-sm"
          {...getComboboxProps()}
        >
          <div
            className="flex w-full"
            key={`inputContainerRef-${selectedItems.length}`}
            ref={inputContainerRef}
          >
            <div
              key={`itemsRef-${selectedItems.length}`}
              className="flex-center"
              ref={itemsRef}
            >
              {selectedItems.length ? (
                <CellLabels
                  maxVisble={maxVisble}
                  handleRemoveItem={handleRemoveItem}
                  {...{ limitedValues, selectedItems, optionValuesToLabel }}
                />
              ) : null}
            </div>
            <input
              type="text"
              className={cn(
                "pr-0 py-0 border-none w-full bg-transparent focus:border-0 focus:outline-0 focus:ring-0",
                { "pl-2": selectedItems.length, "pl-0": !selectedItems.length }
              )}
              autoFocus={autoFocus}
              placeholder={items?.length > 5 ? "search..." : "please select"}
              style={{ maxWidth: isNaN(maxWidth) ? "100%" : maxWidth }}
              {...inputProps}
            />
          </div>
          <div
            className="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md"
            aria-label={"toggle menu"}
          >
            <CaretSortIcon className="h-4 w-4 opacity-50" />
          </div>
        </div>
      </div>
      <ul
        id="options"
        role="listbox"
        className={cn(
          "absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-background p-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
          {
            hidden: !isOpen,
          }
        )}
        {...getMenuProps()}
      >
        {isOpen && filteredItems.length
          ? filteredItems.map((item, index) => {
              const active = highlightedIndex === index;
              const selected = selectedItemValues.includes(item.value);
              return (
                <li
                  className={`${
                    active
                      ? "text-foreground bg-royal-500 shadow-button"
                      : "text-foreground"
                  } cursor-pointer select-none relative py-1 pl-8 pr-4 rounded transition-colors duration-100 ease-in-out`}
                  key={`${item.value}${index}`}
                  {...getItemProps({ item, index })}
                >
                  <>
                    <span
                      className={`${
                        selected ? "font-semibold" : "font-normal"
                      } block truncate ml-4`}
                    >
                      {item.label}
                    </span>
                    <span
                      className={`${
                        active ? "text-foreground" : "text-blue-600"
                      } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                    >
                      {selected ? (
                        <svg
                          width="29"
                          height="12"
                          viewBox="0 0 29 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="29"
                            height="12"
                            rx="6"
                            fill={active ? "black" : "#276DF1"}
                          />
                          <circle cx="23" cy="6" r="4" fill="white" />
                        </svg>
                      ) : (
                        <svg
                          width="29"
                          height="12"
                          viewBox="0 0 29 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="29" height="12" rx="6" fill="#424258" />
                          <circle cx="7" cy="6" r="4" fill="#E2DADA" />
                        </svg>
                      )}
                    </span>
                  </>
                </li>
              );
            })
          : // <li
            //   className={`text-foreground text-sm bg-royal-500 shadow-button group cursor-pointer select-none relative py-1 pl-8 pr-4 rounded transition-colors duration-100 ease-in-out`}
            // >
            //   <span>Create {inputValue}</span>
            // </li>
            null}
      </ul>
    </div>
  );
};
