import firebase from "firebase/app";
import "firebase/analytics";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD3TnF97Hdx_yxQHuKy1gW3JIwgQT_AO34",
  authDomain: "ladder-5a5a9.firebaseapp.com",
  databaseURL: "https://ladder-5a5a9.firebaseio.com",
  projectId: "ladder-5a5a9",
  storageBucket: "ladder-5a5a9.appspot.com",
  messagingSenderId: "371162238839",
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

if ("undefined" !== typeof window) {
  try {
    firebase.initializeApp(firebaseConfig);
    console.log("initialized firebase");
    if ("measurementId" in firebaseConfig) {
      firebase.analytics();
      console.log("activated firebase analytics");
    }
  } catch (error) {
    console.error(error);
  }
}

export const setUserId = (userId) => {
  try {
    firebase.analytics().setUserId(userId);
  } catch (error) {
    console.error("error setting firebase userId", error);
  }
};

export const logEvent = (event, params) => {
  try {
    firebase.analytics().logEvent(event, params);
    console.log("logged firebase event", { event, params });
  } catch (error) {
    console.error(error);
  }
};

export default firebase;
