import { useContentEditDialog } from "apollo/components/content-edit-dialog/container";
import { Field } from "apollo/components/form/field";
import { produce } from "immer";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogTitle,
} from "ui/components/primitives/alert-dialog";

const confirmCTALabel = "Save";

export const ContentEditDialog = () => {
  const {
    state,
    setState,
    fields,
    isOpen,
    setIsOpen,
    changed,
    setChanged,
    title = "",
    handleCancel = () => console.log("canceled"),
    handleConfirm = () => console.log("confirm"),
  } = useContentEditDialog();

  const handleChange = (accessor) => (e) => {
    setChanged((current) => ({ ...current, [accessor]: true }));

    setState((current) =>
      produce(current, (draft) => {
        draft[accessor].value = e?.target?.value || "";
      })
    );
  };
  const handleComboBoxChange = (accessor) => (value) => {
    setChanged((current) => ({ ...current, [accessor]: true }));

    setState((current) =>
      produce(current, (draft) => {
        draft[accessor].value = value;
      })
    );
  };

  return (
    (<AlertDialog open={isOpen} onOpenChange={setIsOpen}>
      <AlertDialogContent className="border" forceMount>
        <AlertDialogTitle className="mb-4 font-medium text-foreground">
          {title}
        </AlertDialogTitle>
        <div className="relative">
          {fields.map((field: any) => (
            // @ts-ignore
            (<Field
              key={field.accessor}
              value={state[field.accessor]?.value}
              onChange={handleChange(field.accessor)}
              onComboBoxChange={handleComboBoxChange(field.accessor)}
              // onUploadImage={handleUploadImage(field.accessor)}
              // onUploadVideo={handleUploadVideo(field.accessor)}
              field={field}
            />)
          ))}
        </div>
        <div className="flex justify-end mt-4 space-x-2">
          <AlertDialogCancel
            onClick={() => {
              setIsOpen(false);
              handleCancel?.();
            }}
          >
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction
            disabled={!Object.keys(changed).length}
            // @ts-ignore
            onClick={() => handleConfirm(state)}
          >
            {confirmCTALabel}
          </AlertDialogAction>
        </div>
      </AlertDialogContent>
    </AlertDialog>)
  );
};
