import * as React from "react";

function Send(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M12.4391 12.6639L3.59744 13.4794L1.27033 20.57C1.20237 20.7751 1.19904 20.9947 1.26077 21.2015C1.32251 21.4084 1.44657 21.5935 1.61761 21.7338C1.78864 21.8741 1.99913 21.9636 2.22299 21.991C2.44684 22.0185 2.67423 21.9827 2.87698 21.8882L22.2549 12.9476C22.4406 12.8613 22.5972 12.727 22.7066 12.56C22.8161 12.393 22.8742 12.2001 22.8742 12.0032C22.8742 11.8063 22.8161 11.6134 22.7066 11.4465C22.5972 11.2795 22.4406 11.1452 22.2549 11.0589L2.89051 2.11177C2.68776 2.01727 2.46037 1.98154 2.23652 2.00899C2.01266 2.03644 1.80217 2.12587 1.63114 2.2662C1.4601 2.40653 1.33604 2.59159 1.2743 2.79846C1.21257 3.00532 1.2159 3.2249 1.28386 3.42998L3.61097 10.5206L12.4357 11.3361C12.6078 11.3523 12.7674 11.4289 12.8835 11.551C12.9996 11.6731 13.0639 11.832 13.0639 11.9968C13.0639 12.1616 12.9996 12.3204 12.8835 12.4426C12.7674 12.5647 12.6078 12.6413 12.4357 12.6575L12.4391 12.6639Z" fill="currentColor"/>
    </svg>
  ) 
}

export default Send;