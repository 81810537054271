import * as React from "react";

function Expand(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.57061 14.301C4.72177 14.301 4.86673 14.361 4.97362 14.4679C5.0805 14.5748 5.14055 14.7198 5.14055 14.8709V18.8605H9.13012C9.28127 18.8605 9.42624 18.9205 9.53312 19.0274C9.64001 19.1343 9.70005 19.2793 9.70005 19.4304C9.70005 19.5816 9.64001 19.7265 9.53312 19.8334C9.42624 19.9403 9.28127 20.0004 9.13012 20.0004H4.57061C4.41945 20.0004 4.27449 19.9403 4.1676 19.8334C4.06072 19.7265 4.00067 19.5816 4.00067 19.4304V14.8709C4.00067 14.7198 4.06072 14.5748 4.1676 14.4679C4.27449 14.361 4.41945 14.301 4.57061 14.301V14.301Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1036 13.8564C10.1567 13.9093 10.1988 13.9722 10.2275 14.0415C10.2563 14.1107 10.2711 14.1849 10.2711 14.2599C10.2711 14.3349 10.2563 14.4091 10.2275 14.4783C10.1988 14.5476 10.1567 14.6105 10.1036 14.6634L4.97417 19.7929C4.86716 19.8999 4.72201 19.96 4.57066 19.96C4.41931 19.96 4.27416 19.8999 4.16714 19.7929C4.06012 19.6858 4 19.5407 4 19.3893C4 19.238 4.06012 19.0929 4.16714 18.9858L9.29659 13.8564C9.34953 13.8033 9.41242 13.7612 9.48166 13.7325C9.55091 13.7037 9.62514 13.6889 9.7001 13.6889C9.77507 13.6889 9.8493 13.7037 9.91854 13.7325C9.98778 13.7612 10.0507 13.8033 10.1036 13.8564ZM19.7926 4.16744C19.8456 4.22038 19.8878 4.28328 19.9165 4.35252C19.9452 4.42176 19.96 4.49599 19.96 4.57096C19.96 4.64592 19.9452 4.72015 19.9165 4.7894C19.8878 4.85864 19.8456 4.92153 19.7926 4.97447L14.6631 10.1039C14.5561 10.2109 14.411 10.2711 14.2596 10.2711C14.1083 10.2711 13.9631 10.2109 13.8561 10.1039C13.7491 9.9969 13.6889 9.85175 13.6889 9.7004C13.6889 9.54905 13.7491 9.4039 13.8561 9.29688L18.9855 4.16744C19.0385 4.11436 19.1014 4.07225 19.1706 4.04352C19.2399 4.01479 19.3141 4 19.3891 4C19.464 4 19.5382 4.01479 19.6075 4.04352C19.6767 4.07225 19.7396 4.11436 19.7926 4.16744V4.16744Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3006 4.57098C14.3006 4.41982 14.3606 4.27485 14.4675 4.16797C14.5744 4.06108 14.7194 4.00104 14.8705 4.00104H19.43C19.5812 4.00104 19.7262 4.06108 19.833 4.16797C19.9399 4.27485 20 4.41982 20 4.57098V9.13048C20 9.28164 19.9399 9.4266 19.833 9.53349C19.7262 9.64037 19.5812 9.70042 19.43 9.70042C19.2789 9.70042 19.1339 9.64037 19.027 9.53349C18.9202 9.4266 18.8601 9.28164 18.8601 9.13048V5.14091H14.8705C14.7194 5.14091 14.5744 5.08087 14.4675 4.97398C14.3606 4.8671 14.3006 4.72213 14.3006 4.57098Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Expand;
