import { CommandCenter } from "apollo/components/command-center";
import { ContentEditDialog } from "apollo/components/content-edit-dialog";
import { ContentEditDialogProvider } from "apollo/components/content-edit-dialog/container";
import { Header } from "apollo/components/top-navigation";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { ConfirmModal } from "ui/components/confirm-modal";
import { useSession } from "ui/containers/session";
import ErrorBoundary from "../error-boundary";

export const LayoutDefault = ({ children }) => {
  const { session, hasAccessedSession } = useSession();
  const { push, pathname } = useRouter();

  useEffect(() => {
    if (!!session || !process?.browser || !hasAccessedSession) {
      return;
    }
    push("/", "/");
  }, [session, push, hasAccessedSession]);

  // if (!session) {
  //   return <LoadingOverlay />;
  // }

  return (
    <ErrorBoundary>
      <ContentEditDialogProvider>
        <div id="default-layout">
          <div className="bg-background">
            <div className="w-full">
              <Header />
              {children}
            </div>
          </div>
          <ContentEditDialog />
          <CommandCenter />
          <ConfirmModal />
        </div>
      </ContentEditDialogProvider>
    </ErrorBoundary>
  );
};
