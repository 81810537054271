import * as React from "react";

function Carrot(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M12.9827 16.8653C12.4643 17.4639 11.5357 17.4639 11.0173 16.8653L3.47056 8.15105C2.74142 7.30911 3.33949 6 4.45327 6H19.5467C20.6605 6 21.2586 7.30911 20.5294 8.15105L12.9827 16.8653Z" fill="currentColor"/>
</svg>

  )
  
}

export default Carrot;