import { produce } from "immer";
import { handleUpdateRequest } from "ui/lib/useRequest";

/* helpers */
export const convertTagsForServer = (tags: string[]): string => {
  return tags.length ? tags.join("; ") : ";";
};

/* requests */
export const setMemberFree = async (
  memberID: string,
  mutate?: any
): Promise<unknown> => {
  const result = await handleUpdateRequest(
    `/v1/makeUserFree`,
    {
      userID: memberID,
    },
    "post"
  );

  if (typeof mutate !== "function") return result;

  await mutate((data) => {
    return produce(data, (member) => {
      if (memberID === member.id) {
        member.membershipStatus = "free";
        return;
      }
    });
  }, false);

  return result;
};

export const setMemberProgramID = async (
  memberID: string,
  program: any,
  mutate?: any
): Promise<unknown> => {
  const result = await handleUpdateRequest(
    `/bootcamps/setUserBootcamp`,
    {
      userId: memberID,
      bootcampId: program.id,
    },
    "post"
  );

  if (typeof mutate !== "function") return result;

  await mutate((data) => {
    return produce(data, (user) => {
      if (memberID === user.id) {
        user.bootcamp = program;
        return;
      }
    });
  }, false);

  return result;
};

export const updateMember = async (memberID, updatedState = {}, mutate) => {
  const response = await handleUpdateRequest(
    `/users/${memberID}`,
    updatedState
  );

  if ("function" !== typeof mutate) return response;

  await mutate(
    `users/detailed?userId=${memberID}`,
    (data) => {
      return {
        ...data,
        users: [
          {
            ...data.users[0],
            ...updatedState,
          },
        ],
      };
    },
    false
  );

  return response;
};
