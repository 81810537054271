import { SearchIcon } from "@heroicons/react/outline";
import { useCommandCenter } from "apollo/containers/command-center";
import { differenceInDays } from "date-fns";
import React from "react";
import useInView from "react-cool-inview";
import { mutate } from "swr";
import { UserFilled } from "ui/components/icons";
import { Spinner } from "ui/components/icons/Spinner";
import { Skeleton } from "ui/components/primitives/skeleton";
import { useMemberSensitive } from "ui/hooks/useMemberSensitive";
import { keyCodes } from "ui/lib/constants";
import { getValidDate } from "ui/lib/helpers";
import { cn } from "ui/lib/utils";
import {
  CommandCenterMembersSearchProvider,
  useCommandCenterMembersSearch,
} from "../containers/members/search";
import { LineItem } from "../line-item";
import { MembersHeader } from "./index";

const Loading = ({ className }) => {
  return <Skeleton className={cn("w-[4rem] h-[29px] rounded-md", className)} />;
};

const LoadMore = ({ onEnter }) => {
  const { ref } = useInView({
    onEnter,
  });

  return <div ref={ref} className="w-full h-[25px] py-4 flex-center"></div>;
};

const SidePanel = ({ member }) => {
  const { data, isFetching } = useMemberSensitive(member?.id);

  if (!data?.id) return null;

  const activeMember = { ...member, ...data };
  const lastActive = activeMember?.lastActive
    ? differenceInDays(new Date(), getValidDate(activeMember.lastActive))
    : 0;

  return (
    <div>
      <div className="flex items-center w-full space-x-4 mb-4">
        <div className="rounded-full bg-secondary flex-center">
          {activeMember.profileImg ? (
            <div
              className={cn("w-full bg-center bg-cover rounded-full")}
              style={{
                minWidth: 50,
                maxWidth: 50,
                height: 50,
                backgroundImage: `url(${activeMember.profileImg}?w=100)`,
              }}
            />
          ) : (
            <div
              style={{ minWidth: 50, maxWidth: 50, height: 50 }}
              className="w-full h-full rounded-full bg-secondary flex-center"
            >
              <UserFilled className="w-4 text-foreground" />
            </div>
          )}
        </div>
        <div className="flex flex-col items-start w-full">
          <span className="text-lg">{`${activeMember.givenName} ${activeMember.familyName}`}</span>
          {isFetching ? (
            <Loading className="h-[1rem]" />
          ) : (
            <span className="text-sm opacity-75">
              {activeMember.bootcamp?.name || "onboarding"}
            </span>
          )}
        </div>
      </div>
      <div className="space-y-2 flex flex-col">
        <div className="flex-center justify-between">
          <p>Membership Status</p>
          {isFetching ? (
            <Loading />
          ) : (
            <span className="px-2 py-1 rounded bg-secondary">
              {activeMember.membershipStatus || "none"}
            </span>
          )}
        </div>
        <div className="flex-center justify-between">
          <p>Last Active</p>
          {isFetching ? (
            <Loading />
          ) : (
            <span className="px-2 py-1 rounded bg-secondary">
              {activeMember.lastActive
                ? lastActive
                  ? `${lastActive} days ago`
                  : "Today"
                : "-"}
            </span>
          )}
        </div>
        <div className="flex-center justify-between">
          <p>Completed Workouts</p>
          {isFetching ? (
            <Loading />
          ) : (
            <span className="px-2 py-1 rounded bg-secondary">
              {activeMember.completionStats?.completionNumber || "none"}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

const ItemLoadingFallBack = ({ height = 46 }) => {
  return (
    <div className="">
      <div
        className="w-full py-2 px-4 space-x-4 skeleton-box flex-center justify-start opacity-50"
        style={{ height }}
      >
        <div
          style={{ minWidth: 25, maxWidth: 25, height: 25 }}
          className="w-full h-full rounded-full bg-secondary flex-center"
        >
          <UserFilled className="w-4 text-foreground" />
        </div>
        <div className="w-1/2 h-3 bg-white rounded-full opacity-25" />
      </div>
    </div>
  );
};

export const Component = () => {
  const {
    inputValue,
    setInputValue,
    filteredData,
    highlightedIndex,
    setHighlightedIndex,
    rowVirtualizer,
    parentRef,
    hasNextPage,
    fetchNextPage,
    isFetching,
    teamID,
    setTeamID,
    bootcamps,
  } = useCommandCenterMembersSearch();
  const { pushToContext } = useCommandCenter();

  const { totalSize, virtualItems } = rowVirtualizer;

  const handleSetUser = (member) => {
    if (!member) return;
    mutate(`users/detailed?userId=${member.id}`, { users: [member] }); // preload data
    pushToContext({ key: "memberId", value: member.id, member });
  };

  const activeMember = filteredData[highlightedIndex];

  const handleKeyDown = (e) => {
    if (e.keyCode === keyCodes.enter) {
      e.preventDefault();
      handleSetUser(activeMember);
    }
  };

  const [focused, setFocused] = React.useState();

  return (
    <>
      <div id="command-center-header" className="shadow">
        <MembersHeader />
        <div className="flex items-center px-3 space-x-1 border-b">
          <div className="relative flex-center">
            {isFetching ? (
              <div className="absolute transform -translate-y-[1.8px]">
                <Spinner className="w-[22px] h-[22px] text-white" />
              </div>
            ) : null}
            <SearchIcon
              className={`w-7 h-7 pl-1 text-neutral-${focused ? "300" : "500"}`}
            />
          </div>
          <input
            autoFocus
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onKeyDown={handleKeyDown}
            onChange={(e) => setInputValue(e.target.value)}
            value={inputValue}
            className="w-full py-4 px-3 text-xl bg-transparent focus:ring-0"
            placeholder="Search members..."
          />
          <select
            className="px-2 py-1 whitespace-nowrap bg-secondary"
            value={teamID || ""}
            onChange={(e) => setTeamID(e.target.value)}
          >
            <option value="">Filter by team</option>
            {bootcamps?.length
              ? bootcamps.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))
              : null}
          </select>
        </div>
      </div>
      <div className="flex min-h-[516px]">
        <div
          ref={parentRef}
          style={{ overflow: "hidden auto" }}
          className="relative w-full"
        >
          <ul className="w-full shadow-md" style={{ height: totalSize }}>
            {virtualItems.length ? (
              virtualItems.map(({ size, start, index }) => {
                // const { bootcamps } = useBootcamps();
                // const teamName = bootcamps
                const member = filteredData[index];
                return (
                  <LineItem
                    key={`${member.value}${index}`}
                    textToHighlight={
                      member.fullName ||
                      `${member.givenName} ${member.familyName}`
                    }
                    data={member}
                    option={{
                      ...member,
                      subtitle: member.bootcampName || "Onboarding",
                    }}
                    size={size}
                    start={start}
                    searchWords={inputValue}
                    highlighted={highlightedIndex === index}
                    onMouseEnter={() => setHighlightedIndex(index)}
                    handleClick={() => handleSetUser(member)}
                    renderIcon={() => {
                      return member.profileImg ? (
                        <div
                          className={cn(
                            "w-full bg-center bg-cover rounded-full"
                          )}
                          style={{
                            minWidth: 25,
                            maxWidth: 25,
                            height: 25,
                            backgroundImage: `url(${member.profileImg}?w=100)`,
                          }}
                        />
                      ) : (
                        <div
                          style={{ minWidth: 25, maxWidth: 25, height: 25 }}
                          className="w-full h-full rounded-full bg-secondary flex-center"
                        >
                          <UserFilled className="w-4 text-foreground" />
                        </div>
                      );
                    }}
                  />
                );
              })
            ) : isFetching ? (
              [...Array(25)].map((_, ndx) => {
                return <ItemLoadingFallBack key={ndx} />;
              })
            ) : (
              <div className="p-12 text-center w-full">no results</div>
            )}
          </ul>
          {hasNextPage ? <LoadMore onEnter={fetchNextPage} /> : null}
        </div>
        <div className="min-w-[50%] p-4 border-l">
          <SidePanel member={activeMember} />
        </div>
      </div>
    </>
  );
};

export const CommandCenterMembersSearch = () => {
  return (
    <CommandCenterMembersSearchProvider>
      <Component />
    </CommandCenterMembersSearchProvider>
  );
};
