import { differenceInDays } from "date-fns";
import { useParams, usePathname } from "next/navigation";
import { useAccount } from "queries/account";
import { useTeam } from "queries/teams";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { Query, QueryKey, useQuery } from "react-query";
import { useGetPaymentStateQuery } from "ui/api-hooks/query/Query";
import { useSession } from "ui/containers/session";
import { getUserPrivileges } from "ui/lib/getUserPrivileges";
import { canUserEdit } from "ui/lib/helpers";
import { useUserEntitlements } from "ui/lib/useUserEntitlements";
import { User } from "ui/types/user";
import { getCurrentUser } from "./services";

const prenatalTeamID = "31bf0334-b750-42fe-981d-5a95b8256f78";
const prenatalTeamCoachIDs = [
  "614035e3-1d63-44a2-95bb-ae63fab9d63e", // samantha
  "612b34d6-8e49-4b0c-a5ed-ab79c8101169", // elise
  "6720ae04-51ef-46ba-ab7e-2f9675fc0493", // lauren
];

export const useCurrentUser = (config?: Query<User, Error, User, QueryKey>) => {
  const booted = useRef(false);
  const { session, setSession } = useSession();
  const pathname = usePathname();
  const params = useParams();
  const activeTeamID = useMemo(() => params?.bootcampId, [params]);
  const {
    data: currentUser,
    error,
    isLoading,
    refetch,
  } = useQuery<User, Error>(["/users/me"], getCurrentUser(session as string), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!session,
    ...(config || {}),
  });

  const { data: account } = useAccount(currentUser?.id as string);
  const { data: activeTeamData } = useTeam(activeTeamID as string);
  const { data: paymentState } = useGetPaymentStateQuery(
    {
      userID: currentUser?.id as string,
    },
    {
      enabled: !!currentUser?.id,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const activeVendorProduct = useMemo(
    () => findActiveVendorProduct(paymentState as VendorData),
    [paymentState]
  );

  const { data: entitlements, isValidating } = useUserEntitlements(
    currentUser?.id
  );
  const privileges = getUserPrivileges(entitlements?.userEntitlements || []);
  const isAdmin = useMemo(
    () => Boolean(currentUser && currentUser.role === "admin"),
    [currentUser]
  );

  const isCoach = useMemo(
    () => Boolean(currentUser && currentUser.role === "coach"),
    [currentUser]
  );

  const isAdminOrCoach = isAdmin || isCoach;

  const isCoachesTeam = useMemo(() => {
    if (!params) return false;
    if (!isCoach) return false;
    if (!currentUser?.id) return false;

    if (activeTeamData?.team.id === prenatalTeamID) {
      return prenatalTeamCoachIDs.includes(currentUser.id);
    }

    if (!activeTeamData?.teamCoaches) return false;
    const teamCoachIDs = activeTeamData.teamCoaches.map((c) => c.coachUserID);
    return teamCoachIDs.includes(currentUser.id);
  }, [isCoach, currentUser, activeTeamData]);

  const canEditData = useCallback(
    (date) => {
      if (!currentUser) return false;
      if (isCoach && !isCoachesTeam) return false;
      if (isAdmin) return true;
      const { id, role } = currentUser;
      return canUserEdit({ id, role, date });
    },
    [isAdmin, currentUser, isCoach, isCoachesTeam]
  );

  const trialData = useMemo(() => {
    if (!currentUser) return { trialing: false };
    if (currentUser.membershipStatus !== "trialing") return { trialing: false };
    if (!currentUser.trialExpiration) return { trialing: false };
    return {
      trialing: true,
      daysRemaining: differenceInDays(
        new Date(currentUser.trialExpiration),
        new Date()
      ),
    };
  }, [currentUser]);

  const canceledOrExpired = useMemo(() => {
    if (!currentUser) return false;
    if (!currentUser.trialExpiration) return false;
    if (!["trialing", "canceled"].includes(currentUser.membershipStatus))
      return false;
    if (currentUser.membershipStatus === "canceled") return true;
    return (
      differenceInDays(new Date(currentUser.trialExpiration), new Date()) <= 0
    );
  }, [currentUser]);

  useEffect(() => {
    // handle banned users. auto log them out and redirect to home
    if (booted.current) return;
    if (pathname === "/") return;
    if (!!currentUser?.isBanned) {
      setSession();
      booted.current = true;
      setTimeout(() => window.location.reload(), 100);
    }
  }, [currentUser, pathname, setSession]);

  return {
    account,
    activeVendorProduct,
    session,
    isAdminOrCoach,
    isCoachesTeam,
    trialData,
    canceledOrExpired,
    currentUser: currentUser?.id ? currentUser : undefined,
    isAdmin,
    isCoach,
    error,
    refetch,
    isLoading: isLoading && !currentUser,
    isLoadingEntitlements: isValidating && !entitlements,
    entitlements,
    privileges,
    canEditData,
  };
};

interface Subscription {
  id: string;
  userID: string;
  vendorProductID: string;
  originalPurchasedTSStr: string;
  currentPeriodStartTSStr: string;
  currentPeriodEndTSStr: string;
  currentPeriodType: string;
  status: string;
}

interface VendorProduct {
  id: string;
  vendorName: string;
  productID: string;
}

interface VendorData {
  subscriptions: Subscription[];
  vendorProducts: VendorProduct[];
}

const findActiveVendorProduct = (
  data: VendorData
): VendorProduct | undefined => {
  if (!data?.subscriptions?.length) return undefined;

  // Find the active subscription
  const activeSubscription = data.subscriptions.find(
    (sub) => sub.status === "active"
  );

  if (!activeSubscription) {
    return undefined;
  }

  // Use the vendorProductID from the active subscription to find the matching vendor product
  const vendorProduct = data.vendorProducts.find(
    (vp) => vp.id === activeSubscription.vendorProductID
  );

  if (!vendorProduct) {
    return undefined;
  }

  return vendorProduct;
};
