const handleTag = (tag = "") => {
  return tag.trim();
};

export const getTags = (value: string | string[]) => {
  if (!value) return [];
  if (Array.isArray(value)) {
    return value.map(handleTag).filter(Boolean);
  }
  return value
    .split(/[;,—]+/)
    .map(handleTag)
    .filter(Boolean);
};
