import React, { ReactNode } from "react";
import { MediaModalShell } from "./shell";

interface MediaModalProps {
  isOpen: boolean;
  deactivateMediaModal: () => void;
  children: ReactNode;
  height?: string | number;
  width?: string | number;
  showOverlay?: boolean;
  className?: string;
}

const MediaModal = React.memo<MediaModalProps>(
  ({
    isOpen,
    deactivateMediaModal,
    children,
    height,
    width,
    showOverlay = true,
    className,
  }) => {
    if (!isOpen) return null;

    return (
      <MediaModalShell
        isOpen={isOpen}
        handleClose={deactivateMediaModal}
        height={height}
        width={width}
        showOverlay={showOverlay}
        className={className}
      >
        {children}
      </MediaModalShell>
    );
  }
);

export default MediaModal;
