import { Suspense } from "react";
import { useEffectOnce } from "react-use";
import ErrorBoundary from "ui/components/error-boundary";
import { useConfirmModal } from "ui/containers/confirm-modal";

const ErrorBoundaryFallback = ({ fallback }) => {
  const {
    setConfirmModalActive,
    setOnConfirm,
    setTitle,
    setConfirmLabel,
    setCancelLabel,
    setDescription,
  } = useConfirmModal();

  useEffectOnce(() => {
    setTitle("Uhoh!");
    setDescription(
      "We had an issue getting data from the server. Please Refresh. If the issue persists, reach out to your local tech guy."
    );
    setConfirmLabel("Refresh page");
    setCancelLabel("Cancel");
    setOnConfirm(() => window.location.reload());
    setConfirmModalActive(true);
  }, []);

  return fallback;
};

export const Suspended = ({ children, ...props }) => {
  const isServer = typeof window === "undefined";
  if (!props.fallback) return children;
  return isServer ? (
    children
  ) : (
    <Suspense {...props}>
      <ErrorBoundary fallback={<ErrorBoundaryFallback {...props} />}>
        {children}
      </ErrorBoundary>
    </Suspense>
  );
};

Suspended.displayName = "Suspended";
