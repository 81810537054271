import { MEMBERS } from "apollo/components/command-center/constants";
import { useCurrentUser } from "queries/current-user";
import { useEffect } from "react";
import { useMethods, usePrevious } from "react-use";
import { useKeyDown } from "ui/lib/hooks";
import { createContainer } from "unstated-next";

type context = {
  key: string;
  value: string;
};

type contexts = context[];

type state = {
  context: contexts;
  active: boolean;
  itemsLength: number;
  modalWidth: number;
};

const initialRoot = { key: "ROOT", value: MEMBERS };

const initialState: state = {
  context: [initialRoot],
  active: false,
  itemsLength: 0,
  modalWidth: 640,
};

type methods = {
  reset: () => state;
  setModalWidth: (value: number) => state;
  setActive: (value: boolean) => state;
  setContext: (value: contexts | any) => state;
  pushToContext: (value: context | contexts) => state;
  goBackInContext: () => state;
  setItemsLength: (value: number) => state;
  handleActivateCommandCenter: (value: state) => state;
  toggleActive: () => state;
};

function createMethods(state: state): methods {
  return {
    reset() {
      return initialState;
    },
    setActive(value) {
      if (!state.context[0]?.value) return state;
      return { ...state, active: value };
    },
    setModalWidth(value) {
      return { ...state, modalWidth: value };
    },
    setContext(value) {
      if ("function" === typeof value)
        return { ...state, context: value(state.context) };
      return { ...state, context: value };
    },
    pushToContext(newContext) {
      if (Array.isArray(newContext)) {
        const filtered = state.context.filter((ctx) => {
          return !(newContext.findIndex(({ key }) => key === ctx.key) > -1);
        });

        return {
          ...state,
          context: [...filtered, ...newContext].filter(({ value }) => !!value),
        };
      }
      return {
        ...state,
        context: [
          ...[...state.context].filter((ctx) => ctx?.key !== newContext.key),
          newContext,
        ].filter(({ value }) => !!value),
      };
    },
    goBackInContext() {
      const nextContext = [...state.context];
      nextContext.pop();
      return { ...state, context: nextContext };
    },
    setItemsLength(itemsLength) {
      return { ...state, itemsLength };
    },
    handleActivateCommandCenter(value) {
      return { ...state, ...value, active: true };
    },
    toggleActive() {
      return { ...state, active: !state.active };
    },
  };
}

const useContainer = () => {
  const { isAdmin } = useCurrentUser();
  const [state, methods] = useMethods<methods, state>(
    createMethods,
    initialState
  );

  const previouslyActive = usePrevious(state.active);

  const pushToRootContext = (value: string) => {
    methods.setContext([{ key: "ROOT", value }]);
  };

  const pushToActionContext = (value: string) => {
    methods.pushToContext({ key: "ACTION", value });
    methods.setActive(true);
  };

  const resetToRoot = () => {
    methods.setContext((context) => {
      if (!context?.length) return context;

      const res = context.filter((n) => n?.key === "ROOT");
      return res;
    });
  };

  const getFromContext = (keyToFind) =>
    state.context.find(({ key }) => key === keyToFind)?.value;

  useEffect(() => {
    if (state.active) return;

    // when closing the command center,
    // reset the context to only be the root
    resetToRoot();
  }, [state.active]);

  // cmd + K
  useKeyDown(
    75,
    (e) => {
      if (e.metaKey && isAdmin) {
        methods.setActive(true);
      }
    },
    [isAdmin]
  );

  // cmd + backspace
  useKeyDown(
    8,
    (e) => {
      if (e.metaKey && state.context.length > 1) {
        methods.goBackInContext();
      }
    },
    [state.context]
  );

  //escape
  useKeyDown(27, () => methods.setActive(false), [methods.setActive]);

  return {
    ...state,
    ...methods,
    previouslyActive,
    resetToRoot,
    getFromContext,
    pushToActionContext,
    pushToRootContext,
  };
};

export const {
  useContainer: useCommandCenter,
  Provider: CommandCenterProvider,
} = createContainer(useContainer);
