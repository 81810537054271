import * as React from "react";

function Mic(props) {
  return (
    <svg viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M9 11.5312C9.8948 11.5302 10.7527 11.1743 11.3854 10.5416C12.0181 9.90891 12.374 9.05105 12.375 8.15625V3.9375C12.375 3.04239 12.0194 2.18395 11.3865 1.55101C10.7536 0.918079 9.89511 0.5625 9 0.5625C8.10489 0.5625 7.24645 0.918079 6.61351 1.55101C5.98058 2.18395 5.625 3.04239 5.625 3.9375V8.15625C5.626 9.05105 5.98191 9.90891 6.61462 10.5416C7.24734 11.1743 8.1052 11.5302 9 11.5312ZM6.75 3.9375C6.75 3.34076 6.98705 2.76847 7.40901 2.34651C7.83097 1.92455 8.40326 1.6875 9 1.6875C9.59674 1.6875 10.169 1.92455 10.591 2.34651C11.0129 2.76847 11.25 3.34076 11.25 3.9375V8.15625C11.25 8.75299 11.0129 9.32528 10.591 9.74724C10.169 10.1692 9.59674 10.4062 9 10.4062C8.40326 10.4062 7.83097 10.1692 7.40901 9.74724C6.98705 9.32528 6.75 8.75299 6.75 8.15625V3.9375Z"
        fill="currentColor"
      />
      <path
        d="M14.0625 6.1875V8.15625C14.0625 10.9477 11.7914 13.2188 9 13.2188C6.20859 13.2188 3.9375 10.9477 3.9375 8.15625V6.1875H2.8125V8.15625C2.8125 11.3784 5.28834 14.0328 8.4375 14.3179V17.4375H9.5625V14.3179C12.7117 14.0328 15.1875 11.3784 15.1875 8.15625V6.1875H14.0625Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Mic;
