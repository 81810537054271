import React, { useCallback, useEffect, useState } from "react";
import { createContainer } from "unstated-next";

const useContainer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [details, setDetails] = useState("");
  const [fields, setFields] = useState([]);
  const [state, setState] = useState({});
  const [changed, setChanged] = useState({});

  const handleConfirm = React.useRef(() => null);
  const handleCancel = React.useRef(() => null);

  const ensureConfirmed = ({
    title,
    details,
    onConfirm,
    onCancel,
    initialState = {},
    fields = [],
  }) => {
    handleConfirm.current = onConfirm;
    handleCancel.current = onCancel;
    setState(initialState);
    setFields(fields);
    setTitle(title);
    setDetails(details);
    setIsOpen(true);
  };

  return {
    fields,
    isOpen,
    title,
    details,
    state,
    changed,
    setChanged,
    setState,
    setIsOpen,
    ensureConfirmed,
    handleConfirm: handleConfirm.current,
    handleCancel: handleCancel.current,
  };
};

const {
  useContainer: useContentEditDialog,
  Provider: ContentEditDialogProvider,
} = createContainer(useContainer);

export { useContentEditDialog, ContentEditDialogProvider };
