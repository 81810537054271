import React, { forwardRef, useEffect, useRef, useState } from "react";
import { checkIsHlsMedia, useHlsMedia } from "ui/lib/use-hls-media";

export const VideoPlayerWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <div className="absolute inset-0 flex-center bg-secondary w-full">
      {children}
    </div>
  );
};

interface HlsVideoPlayerProps extends VideoPlayerProps {
  playerRef: React.RefObject<HTMLVideoElement>;
}

const HlsVideoPlayer: React.FC<HlsVideoPlayerProps> = ({
  url,
  playerRef,
  autoPlay = true,
  loop = true,
  controls = false,
  ...rest
}) => {
  useHlsMedia({
    ref: playerRef,
    autoPlay,
    url,
  });

  return (
    <video
      src={url}
      width="440"
      height="auto"
      autoPlay={autoPlay}
      controls={controls}
      loop={loop}
      ref={playerRef}
      {...rest}
    />
  );
};

interface VideoPlayerProps {
  autoPlay?: boolean;
  loop?: boolean;
  controls?: boolean;
  url: string;
  [key: string]: any;
}

export const VideoPlayer = forwardRef<HTMLVideoElement, VideoPlayerProps>(
  (
    { autoPlay = true, loop = true, controls = false, url, ...rest },
    forwardedRef
  ) => {
    let ref = useRef<HTMLVideoElement>(null);
    // Function to get the current ref, whether it's the local ref or the forwarded ref
    const getRef = () => {
      return (
        (forwardedRef && typeof forwardedRef === "function"
          ? forwardedRef(ref.current)
          : forwardedRef) || ref
      );
    };

    const isHlsMesia = checkIsHlsMedia(url);

    if (isHlsMesia) {
      return (
        <HlsVideoPlayer
          loop={loop}
          url={url}
          playerRef={ref}
          autoPlay={autoPlay}
          controls={controls}
          {...rest}
        />
      );
    }

    return (
      <video
        loop={loop}
        width="440"
        height="auto"
        autoPlay={autoPlay}
        controls={controls}
        ref={getRef()}
        {...rest}
      >
        <source src={url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  }
);

interface MultiVideoPlayerProps {
  urls: string[];
  autoPlay?: boolean;
  loop?: boolean;
  controls?: boolean;
  [key: string]: any;
}

export const MultiVideoPlayer = forwardRef<
  HTMLVideoElement,
  MultiVideoPlayerProps
>(
  (
    { urls, autoPlay = true, loop = true, controls = false, ...rest },
    forwardedRef
  ) => {
    const [currentUrlIndex, setCurrentUrlIndex] = useState(0);
    const videoRef = useRef<HTMLVideoElement>(null);

    const getRef = () => {
      return (
        (forwardedRef && typeof forwardedRef === "function"
          ? forwardedRef(videoRef.current)
          : forwardedRef) || videoRef
      );
    };

    const currentUrl = urls[currentUrlIndex];

    const handleEnded = () => {
      setCurrentUrlIndex((prevIndex) =>
        loop ? (prevIndex + 1) % urls.length : prevIndex + 1
      );
    };

    useEffect(() => {
      const ref = getRef();
      if ("current" in ref && ref.current) {
        ref.current.load();
        if (autoPlay) {
          ref.current
            .play()
            .catch((error) => console.error("Error playing video:", error));
        }
      }
    }, [currentUrlIndex, autoPlay]);

    if (currentUrlIndex >= urls.length) {
      return null; // All videos have been played and loop is false
    }

    return (
      <VideoPlayer
        ref={getRef()}
        url={currentUrl}
        autoPlay={autoPlay}
        controls={controls}
        loop={false} // We handle looping manually
        onEnded={handleEnded}
        width="440"
        height="auto"
        {...rest}
      />
    );
  }
);
