import * as Sentry from "@sentry/nextjs";
import { useDefaultCommandCenterControls } from "apollo/components/command-center/hooks";
import { setMemberFree } from "apollo/components/member/requests";
import { useCommandCenter } from "apollo/containers/command-center";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useEffectOnce } from "react-use";
import { useErrorNotification } from "ui/containers/error-notification";
import { createContainer } from "unstated-next";
import { useCommandCenterMembers } from ".";

const items = ["canceling", "free"];
const data = items.map((name) => ({ name, id: name }));

const useContainer = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(false);
  const { activateError } = useErrorNotification();
  const { setItemsLength, setActive } = useCommandCenter();
  const { member, memberId, mutateMember } = useCommandCenterMembers();

  useEffectOnce(() => {
    setItemsLength(data.length);
  });

  const props = useDefaultCommandCenterControls({
    data: data,
    matchKeys: ["name"],
  });

  const handleCancel = () => {
    setActive(false);
  };

  const handleSetMemberFree = async () => {
    try {
      setIsSaving(true);
      setError(false);

      toast.promise(
        setMemberFree(member.id, mutateMember),
        {
          loading: "Loading...",
          error: "Something went wrong, please try again",
          success: `Saved! Member has been updated to free`,
        },
        {
          position: "top-center",
        }
      );

      setIsSaving(false);
      setActive(false);
    } catch (error) {
      console.error(error);
      setIsSaving(false);
      setError(true);
      activateError("Unable to save");
      Sentry.captureException(error);
    }
  };

  return {
    ...props,
    isSaving,
    handleCancel,
    member,
    handleSetMemberFree,
  };
};

export const {
  useContainer: useCommandCenterMembersStatus,
  Provider: CommandCenterMembersStatusProvider,
} = createContainer(useContainer);
