import * as Sentry from "@sentry/nextjs";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSession } from "ui/containers/session";
import { createContainer } from "unstated-next";

interface VersionData {
  version: string;
  changelog: string;
}

const useContainer = () => {
  const { session } = useSession();
  const [version, setVersion] = useState<string>("");
  const [changelog, setChangelog] = useState<string>("");

  const getLatestVersion = (): Promise<VersionData> => {
    return new Promise((resolve, reject) => {
      return fetch("/api/authenticated/version")
        .then((response) => {
          if (response.status !== 200) {
            return reject(new Error("failed to get latest version"));
          }
          response.json().then((data: VersionData) => resolve(data));
        })
        .catch((error: Error) => {
          console.error(error);
          Sentry.captureEvent(error);
          reject(new Error("failed to get latest version"));
        });
    });
  };

  const { data } = useQuery<VersionData | undefined>(
    session ? "/api/authenticated/version" : "",
    getLatestVersion,
    {
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  );

  useEffect(() => {
    if (!data) return;
    const { version: latestVersion, changelog } = data;
    if (latestVersion) {
      setVersion((version) => (version ? version : latestVersion));
      setChangelog(changelog);
    }
  }, [data]);

  return {
    version,
    changelog,
    latestVersion: data?.version,
    getLatestVersion,
  };
};

export const { useContainer: useVersion, Provider: VersionProvider } =
  createContainer(useContainer);
