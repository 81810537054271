"use client";
import * as React from "react";
import { useConfig } from "ui/hooks/use-config";

export function ThemeSwitcher() {
  const [config] = useConfig();

  React.useEffect(() => {
    const theme = config.theme;
    let shouldAddTheme = false;
    if (theme) {
      document.body.classList.forEach((className) => {
        if (className.match(/^theme.*/) && className !== `theme-${theme}`) {
          shouldAddTheme = true;
          document.body.classList.remove(className);
        }
      });

      if (!shouldAddTheme) return;
      return document.body.classList.add(`theme-${theme}`);
    }
  }, [config.theme]);

  return null;
}
