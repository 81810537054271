import * as Sentry from "@sentry/nextjs";
import { useDefaultCommandCenterControls } from "apollo/components/command-center/hooks";
import { setMemberProgramID } from "apollo/components/member/requests";
import { useCommandCenter } from "apollo/containers/command-center";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useEffectOnce } from "react-use";
import { useErrorNotification } from "ui/containers/error-notification";
import { keyCodes } from "ui/lib/constants";
import useRequest from "ui/lib/useRequest";
import { createContainer } from "unstated-next";
import { useCommandCenterMembers } from ".";

const useContainer = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(false);
  const { activateError } = useErrorNotification();
  const { setItemsLength } = useCommandCenter();
  const { member, memberId, mutateMember } = useCommandCenterMembers();
  const { data = [] } = useRequest("bootcamps");

  useEffectOnce(() => {
    setItemsLength(data.length);
  });

  const props = useDefaultCommandCenterControls({
    data: data,
    matchKeys: ["name"],
  });

  const handleSetMemberProgram = async (bootcamp) => {
    try {
      setIsSaving(true);
      setError(false);

      toast.promise(
        setMemberProgramID(memberId as string, bootcamp, mutateMember),
        {
          loading: "Loading...",
          error: "Something went wrong, please try again",
          success: `Saved! Program has been updated to ${bootcamp.name}`,
        },
        {
          position: "top-center",
        }
      );

      setIsSaving(false);
      // toggleActive();
    } catch (error) {
      setIsSaving(false);
      setError(true);
      activateError("Unable to save");
      Sentry.captureException(error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === keyCodes.enter) {
      e.preventDefault();
      handleSetMemberProgram(props.filteredData[props.highlightedIndex]);
    }
  };

  return { ...props, isSaving, handleKeyDown, member, handleSetMemberProgram };
};

export const {
  useContainer: useCommandCenterMembersProgram,
  Provider: CommandCenterMembersProgramProvider,
} = createContainer(useContainer);
