import { request } from "graphql-request";
import { jsonToGraphQLQuery } from "json-to-graphql-query";
import { useInfiniteQuery, useQuery } from "react-query";
import { useSession } from "ui/containers/session";

const endpoint = process.env.NEXT_PUBLIC_API_BASE_URL_ROCKY_GRAPHQL;

export const memberRequestKeys = {
  email: true,
  createdTS: true,
  profileImgImgixURL: true,
  gender: true,
  dob: true,
  roleName: true,
  membershipStatus: true,
  lastActivityTS: true,
  trialExpirationTS: true,
  tags: true,
  note: true,
  isPushEnabled: true,

  // adds 2s to query time
  // coachRoomID: true,
  // teamRoomID: true,
  // coachRoomLastMessageSentTS: true,
  // coachRoomLastCoachMessageRecievedTS: true,
  // coachRoomLastImpersonatedCoachMessageRecievedTS: true,
  // teamRoomLastMessageSentTS: true,

  // adds 15s to query time
  // activeRoomFlagTypes: true,
  // avgWorkoutRating: true,
  // numWorkoutsCompleted: true,
  // numWorkoutsCompleted7Days: true,
  // numWorkoutsCompleted14Days: true,
  // numWorkoutsCompleted30Days: true,
  // numWorkoutsIncomplete: true,
  // lastCompletedWorkoutID: true,
  // lastCompletedWorkoutWorkoutID: true,
  // lastCompletedWorkoutCreatedTS: true,
  // lastCompletedWorkoutCompletedTS: true,
  // lastCompletedWorkoutCompletionNumber: true,
  // lastCompletedWorkoutRating: true,

  // adds 10s to query time
  // numTeamJoins: true,

  // adds 8s to query time
  // healthStatus: true,

  // adds 2s to query time
  // recommendedTeamIDs: true,

  // adds 6s to query time
  // recentTeamHistory: {
  //   teamID: true,
  //   teamName: true,
  //   joinedTS: true,
  // },

  // adds 5s to query time
  // roomFlagsAreActive: true,

  // adds 1s to query time
  // latestTrialPeriodStartTS: true,
  // latestTrialPeriodEndTS: true,
  // latestTrialPeriodReason: true,

  teamID: true,

  utmSource: true,
  utmContent: true,
  utmCampaign: true,
  utmMedium: true,
  utmTerm: true,
  utmType: true,

  subscriptionStripeCustomerID: true,
  subscriptionStatus: true,
  subscriptionType: true,
  subscriptionIsCancelingAtPeriodEnd: true,
  subscriptionCurrentPeriodEndTS: true,
  subscriptionCurrentPeriodStartTS: true,

  introTrialPeriodStartTS: true,
  introTrialPeriodEndTS: true,

  // flagSettings: {
  //   flagType: true,
  //   // muteStartTS: true,
  //   // muteEndTS: true,
  // },
};

export function useDetailedUser(
  { node, filter, sort = [], active } = {},
  props = []
) {
  const { session } = useSession();
  return useQuery(
    active ? ["detailedUser", ...props] : null,
    async ({ pageParam: after = "" }) => {
      return await request(
        endpoint,
        jsonToGraphQLQuery(
          {
            query: {
              detailedUsers: {
                __args: {
                  filter,
                  sort,
                },
                debug: {
                  sqlText: true,
                },
                edges: {
                  node,
                },
              },
            },
          },
          { pretty: true }
        ),
        {},
        {
          Authorization: `Bearer ${session}`,
          "Content-Type": "application/json",
        }
      );
    }
  );
}
export function useDetailedUsers(
  { node, filter, sort = [], active, first = 25 } = {},
  props = []
) {
  const { session } = useSession();
  return useInfiniteQuery(
    active ? ["detailedUsers", ...props] : null,
    async ({ pageParam: after = "" }) => {
      return await request(
        endpoint,
        jsonToGraphQLQuery(
          {
            query: {
              detailedUsers: {
                __args: {
                  first,
                  after,
                  filter,
                  sort,
                },
                totalCount: true,
                pageInfo: {
                  hasPreviousPage: true,
                  hasNextPage: true,
                  startCursor: true,
                  endCursor: true,
                },
                debug: {
                  sqlText: true,
                },
                edges: {
                  cursor: true,
                  node,
                },
              },
            },
          },
          { pretty: true }
        ),
        {},
        {
          Authorization: `Bearer ${session}`,
          "Content-Type": "application/json",
        }
      );
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage?.detailedUsers?.edges?.length) return;
        if (!lastPage?.detailedUsers?.pageInfo?.hasNextPage) return;

        const { cursor } =
          lastPage.detailedUsers.edges[lastPage.detailedUsers.edges.length - 1];
        return cursor;
      },
    }
  );
}
